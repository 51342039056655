import Service from "./service";

export default class AuthService extends Service {

  static get = async (
    token = Service.token()
  ) => {
    try {
      const res = await fetch(`${Service.getAPIData()}/users/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static login = async (params: any) => {
    try {
      const response = await fetch(`${Service.getAPIData()}/auth/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${params}`
        },
        mode: "cors",
        body: JSON.stringify({loginType: "matanativa"})
      });

      if(response.status === 200)
      {
        return Promise.resolve(response.json())
      }
      else{
        return Promise.reject(response.status);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };


  static loginUser = async(login: string, pass: string) =>{
    return await AuthService.login(window.btoa(login+":"+pass));
  }

}
