import React, { LazyExoticComponent, useEffect, useId, useState } from "react";

import { Navigate, Route, Routes } from "react-router";
import { HashRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "semantic-ui-react";

import { AuthService } from "#services";
import Service from "../../services/service";
import Cookie from "js-cookie";
import PageLoader from "#components/global/pageLoader";
import { Auth, GlobalMessage, ProjectInfo } from "#components";

const TopBar = React.lazy(() => import("#components/global/menu"));
const ProjectSidebar = React.lazy(() => import("#components/global/sidebar"));

const Clientes = React.lazy(() => import("#views/Clientes"));
const Licences = React.lazy(() => import("#views/Licences"));
const Vouchers = React.lazy(() => import("#views/Voucher"));
const Error404 = React.lazy(() => import("#views/404"));
const Login = React.lazy(() => import("#views/Login"));
const Products = React.lazy(() => import("#views/Products"));
const Transactions = React.lazy(() => import("#views/Transactions"));
const Buscas = React.lazy(() => import("#views/Buscas"));
const UserManagement = React.lazy(() => import("#views/UserManagement"));
const Dashboard = React.lazy(() => import("#views/Dashboard"));
const Companies = React.lazy(() => import("#views/Companies"));
const CompanyManagement = React.lazy(() => import("#views/CompanyManagament"));
const Calculos = React.lazy(() => import("#views/Calculos"));
const Rotinas = React.lazy(() => import("#views/Rotinas"));

const routes = [
  { path: "/", exact: true, component: Dashboard },
  { path: "/clientes", exact: true, component: Clientes },
  { path: "/clientes/profile", exact: false, component: UserManagement },
  { path: "/buscas", exact: true, component: Buscas },
  { path: "/licences", exact: true, component: Licences },
  { path: "/transactions", exact: true, component: Transactions },
  { path: "/vouchers", exact: true, component: Vouchers },
  { path: "/products", exact: true, component: Products },
  { path: "/auth", exact: true, component: Login },
  { path: "/404", exact: true, component: Error404 },
  { path: "/companies", exact: true, component: Companies },
  { path: "/companies/profile", exact: false, component: CompanyManagement },
  { path: "/calculos", exact: true, component: Calculos },
  { path: "/rotinas", exact: true, component: Rotinas },
];

function Router(props) {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(true);
  const { data } = useSelector((state: any) => ({ data: state.project }));
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));

  const id = useId();

  useEffect(() => {
    const token = Service.token();
    if (token) {
      authenticate();
    } else {
      console.log("REDIRECT_FAILED_AUTH");
      window.location.assign(`${process.env.REACT_APP_LOGIN_URL}/#/auth`);
      setReady(true);
    }
  }, []);

  const authenticate = async () => {
    try {
      await AuthService.get()
        .then((userData) => {
          if (userData.content.administrador) {
            dispatch({
              type: "setUser",
              payload: {
                data: userData.content,
                permissions: userData.content.administrador,
              },
            });
            //redirect to main page
            window.location.assign(`${process.env.REACT_APP_LOGIN_URL}/#/`);
            setReady(true);
          } else {
            Cookie.remove("aTkn");
            window.location.assign(`${process.env.REACT_APP_LOGIN_URL}/#/auth`);
          }
        })
        .catch((e) => {
          Cookie.remove("aTkn");
          window.location.assign(`${process.env.REACT_APP_LOGIN_URL}/#/auth`);
        });
    } catch (e) {
      console.error(e);
      setReady(true);
    }
  };

  useEffect(() => {
    if (theme.darkMode) document.body.classList.add("dark");
    else document.body.classList.remove("dark");
  }, [theme]);

  const RouteRender = ({
    Component,
    path,
  }: {
    Component?: LazyExoticComponent<any>;
    path?: String;
  }) => {
    return (
      <Container fluid>
        {path === "/auth" ? (
          <Component />
        ) : (
          <>
            <GlobalMessage />
            <Auth />
            <ProjectInfo />
            <ProjectSidebar className={"white"}>
              <Component />
            </ProjectSidebar>
          </>
        )}
      </Container>
    );
  };

  return (
    <div className="full bg router">
      <HashRouter basename="/">
        <>
          <React.Suspense
            fallback={
              <>
                <PageLoader suspense active={true} />
              </>
            }
          >
            <TopBar />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={id}
                  path={route.path}
                  element={
                    <RouteRender
                      path={route.path}
                      Component={route.component}
                      key={route.path}
                    />
                  }
                />
              ))}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </React.Suspense>
        </>
      </HashRouter>
    </div>
  );
}

export default Router;
