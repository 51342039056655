import React from "react";
import {animateDots, animateLogoFast} from "#static/img/"
import {Loader} from "semantic-ui-react";

interface props{
active?: boolean;
suspense?: boolean;
}

const PageLoader:React.FC<props> = (props) =>{

    return(
        <span className={!!props.active ? "pageLoader" : "pageLoader hidden"}>
            <object className={"animatedSVG"} type="image/svg+xml" data={animateLogoFast}>svg-animation</object>
            {props.suspense?
                <Loader active={props.active} inline={"centered"}/>
                :
                <object className={"animateDots"} type="image/svg+xml" data={animateDots}>svg-animation</object>
            }
        </span>

    );
}

export default PageLoader;
