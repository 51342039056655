/**
 * redux to store the selected tab when switching from expanded and collapsed
 * @param state
 * @param action
 */
export function darkMode(
  state: { darkMode: any} = { darkMode: false },
  action: { type: string; payload: { darkMode: any;} }
) {
  switch (action.type) {
    case "setDarkMode":
      return {darkMode: true }
    case "setLightMode":
      return {darkMode: false }
    default:
      return state;
  }
}
