import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {Breadcrumb, Button, Menu} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {GlobalContext} from "#providers";

/* Main Component */

interface props {}

let ProjectInfo: React.FC<props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useSelector((state: any) => ({ data: state.project }));
  const { sidebar } = useSelector((state: any) => ({
    sidebar: state.selectedMenu,
  }));
  const [globalContext, dispatch] = useContext(GlobalContext);

  const TABS = {
    TREES: "Árvores",
    SPECIES: "Espécies",
    PROJECT: "Projeto",
    FORMULAS: "Fórmulas",
    PARCELS: "Parcelas",
    CALCULATE: "Resultado de cálculo",
  };

  const closeProject = () => {
    dispatch({ type: "clearProject" });
    navigate("/");
  };

  return (
    <>
      {data && (
        <Menu secondary className={"projectInfoHeader"}>
          <Menu.Menu
            as={Button}
            onClick={() => closeProject()}
            className={"closeProjectButton buttonBorderPrimary"}
            position={"left"}
          >
            Fechar Projeto
          </Menu.Menu>
          <div className={"projectInfoDivider"} />
          <Breadcrumb className={"breadcrumbProject"} size={"huge"}>
            <Breadcrumb.Section>{data && data.nome}</Breadcrumb.Section>
            {!!sidebar && (
              <>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section active>
                  {TABS[sidebar.sidebar]}
                </Breadcrumb.Section>
              </>
            )}
          </Breadcrumb>
        </Menu>
      )}
    </>
  );
};

export default ProjectInfo;
