import Service from "./service";

export default class SearchesService extends Service {
  private static getExpiring = async (params) => {
    try {
      const token = Service.token();
      let searchString = params.days != "" ? `days=${params.days}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/licences/expiresearch?${searchString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getActiveUsersWithLicences = async (params) => {
    try {
      const token = Service.token();
      let searchString =
        params.dateLimit != "" ? `date=${params.dateLimit}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/licences/count/activeusers?${searchString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static listActiveUsersWithLicences = async (params) => {
    try {
      const token = Service.token();
      let searchString =
        params.dateLimit != "" ? `date=${params.dateLimit}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/licences/list/activeusers?${searchString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getActiveLicences = async (params) => {
    try {
      const token = Service.token();
      let beginString =
        params.beginDate != "" ? `&beginDate=${params.beginDate}` : "";
        let endString =
        params.endDate != "" ? `&endDate=${params.endDate}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/licences/activelicences?${beginString}${endString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getCreatedAccounts = async (params) => {
    try {
      const token = Service.token();
      let beginString =
        params.beginDate != "" ? `&beginDate=${params.beginDate}` : "";
        let endString =
        params.endDate != "" ? `&endDate=${params.endDate}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/users/countaccounts?${beginString}${endString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getCreatedProjects = async (params) => {
    try {
      const token = Service.token();
      let beginString =
        params.beginDate != "" ? `&beginDate=${params.beginDate}` : "";
        let endString =
        params.endDate != "" ? `&endDate=${params.endDate}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/projects/count?${beginString}${endString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static listUsersByAccessOnPeriod = async (params) => {
    try {
      const token = Service.token();
      let beginString =
        params.beginDate != "" ? `&beginDate=${params.beginDate}` : "";
        let endString =
        params.endDate != "" ? `&endDate=${params.endDate}` : "";
      const res = await fetch(
        `${Service.getAPIData()}/admin/users/search/access?${beginString}${endString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static countUsersWithActiveLicences = async (params) => {
    return await SearchesService.getActiveUsersWithLicences(params);
  };
  static listUsersWithActiveLicences = async (params) => {
    return await SearchesService.listActiveUsersWithLicences(params);
  };

  static getLicencesExpiring = async (params) => {
    return await SearchesService.getExpiring(params);
  };

  static getActiveLicencesOnPeriod = async (params) => {
    return await SearchesService.getActiveLicences(params);
  };

  static getCreatedAccountsOnPeriod = async (params) => {
    return await SearchesService.getCreatedAccounts(params);
  };

  static getUsersByAccessOnPeriod = async (params) => {
    return await SearchesService.listUsersByAccessOnPeriod(params);
  };

  static getCreatedProjectsOnPeriod = async (params) => {
    return await SearchesService.getCreatedProjects(params);
  };
}
