import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Image,
  Popup,
  Segment,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import { MessageContext } from "#providers";
import { colors } from "#static/json";
import { ClientesService } from "#services";
import { userImage } from "#static/img";

interface params {
  user: any;
}
const UserInformation: React.FC<params> = (props) => {
  const { t } = useTranslation();
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const defaultState = {
    email: "",
    cpf: "",
    telefone: "",
    cep: "",
    cidade: "",
    estado: "",
    endereco: "",
    nome: "",
    areaatuacao: "",
    ocupacao: "",
    avatar: "",
    observacao: "",
    createdat: "",
    updatedat: "",
    numeroprojetos: "",
    numerotransacoes: "",
    tamanhophotoskbytes: "",
    ultimoacesso: "",
  };

  const [messageContext, setMessageContext] = useContext(MessageContext);
  const [userParams, setUserParams] = useState(defaultState);
  const [userNeutralParams, setNeutralParams] = useState(defaultState); // Temp params when canceling edit

  useEffect(() => {
    if (props.user) {
      setUserParams(props.user);
      setNeutralParams(props.user);
    }
  }, [props.user]);

  const isValid = (value: any) =>
    value != null && value != null && value != undefined;

  const toggleEdit = () => {
    setDisabled(!disabled);
  };
  const cancelEdit = () => {
    setUserParams(userNeutralParams);
    toggleEdit();
  };
  const updateUserInfo = () => {
    setLoading(true);
    ClientesService.updateUser({
      payload: {
        observacao: userParams.observacao,
      },
    })
      .then(({ content }: any) => {
        setLoading(false);
        toggleEdit();
        setMessageContext({
          id: 1,
          title: "Perfil atualizado!",
          description: "As informações do perfil foram atualizadas",
          backgroundColor: colors.success,
          icon: "check",
        });
      })
      .catch((err) => {
        setMessageContext({
          id: 1,
          title: "Erro ao atualizar o usuário",
          description: "Aconteceu um erro ao atualizar o usuário " + err.status,
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
      });
  };

  const handleFormChange = (field: string, data: any) => {
    setUserParams({ ...userParams, [field]: data.value });
  };

  const converterKBparaGB = (type: any) => {
    if (type != null && type != undefined && type != "") {
      // Um gigabyte equivale a 1024^2 kilobytes
      let gb = type / Math.pow(1024, 2);
      // Retornar o valor em gigabytes
      return `${gb.toFixed(2)} GB`;
    } else {
      return `0 GB`;
    }
  };

  return (
    <>
      <Grid.Column width={4}>
        <Segment.Group piled>
          <Segment
            raised
            className="userProfile"
            style={{ height: "12vh", background: "rgba(20, 71, 20, 0.7)" }}
          >
            <Image
              src={
                isValid(userParams.avatar)
                  ? `${process.env.REACT_APP_AWS_BUCKET_USERS_AVATAR}/${userParams.avatar}`
                  : userImage
              }
              size="small"
              centered
              circular
              style={{ marginBottom: "2rem", marginTop: "2rem", zIndex: 2 }}
            />
          </Segment>
          <Segment raised style={{ paddingTop: "6rem" }}>
            <Form>
              <span
                className="text__form__title"
                style={{
                  paddingBottom: "6px",
                  textAlign: "center",
                  width: "100%",
                  display: "block",
                  marginTop: "3rem",
                }}
              >
                {isValid(userParams.nome) ? userParams.nome : "Não informado"}
              </span>

              <div
                style={{
                  display: "block",
                  paddingBottom: "6px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <span className="text__secondary">
                  {isValid(userParams.email)
                    ? userParams.email
                    : "Não informado"}
                </span>
              </div>

              <br />

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "20px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  CPF
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.cpf) ? userParams.cpf : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "20px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Telefone
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.telefone)
                    ? userParams.telefone
                    : "Não informado"}
                </span>
              </div>

              <br />

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Endereço
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.endereco)
                    ? userParams.endereco
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Cidade
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.cidade)
                    ? userParams.cidade
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  CEP
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.cep) ? userParams.cep : "Não informado"}
                </span>
              </div>

              <br />

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Área de Atuação
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.areaatuacao)
                    ? userParams.areaatuacao
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Ocupação
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.ocupacao)
                    ? userParams.ocupacao
                    : "Não informado"}
                </span>
              </div>

              <br />

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Cliente desde
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.createdat)
                    ? userParams.createdat
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Última atualização de dados em
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.updatedat)
                    ? userParams.updatedat
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Último acesso em
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.ultimoacesso)
                    ? userParams.ultimoacesso
                    : "Não informado"}
                </span>
              </div>

              <br />

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Número de Projetos Criados
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.numeroprojetos)
                    ? userParams.numeroprojetos
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Número de Transações Efetivadas
                </span>
                <span className="text__header__secondary">
                  {isValid(userParams.numerotransacoes)
                    ? userParams.numerotransacoes
                    : "Não informado"}
                </span>
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Uso de Armazenamento de Fotos
                </span>
                <span className="text__header__secondary">
                  {converterKBparaGB(userParams.tamanhophotoskbytes)}
                </span>
              </div>

              <br />

              <div
                style={{
                  paddingLeft: "10px",
                  paddingBottom: "16px",
                  paddingRight: "10px",
                }}
              >
                <span
                  className="text__label"
                  style={{
                    display: "block",
                    paddingBottom: "4px",
                  }}
                >
                  Observação&nbsp;&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ fontSize: "1em", marginBottom: "2px" }}
                      />
                    }
                    content={"Essa informação não é visível para o cliente."}
                  ></Popup>
                </span>
                {disabled ? (
                  <span className="text__header__secondary">
                    {isValid(userParams.observacao)
                      ? userParams.observacao
                      : "Não informado"}
                  </span>
                ) : (
                  <Form.TextArea
                    fluid
                    value={userParams.observacao}
                    onChange={(e, value) =>
                      handleFormChange("observacao", value)
                    }
                  ></Form.TextArea>
                )}
              </div>

              <br />
              <Divider />

              <br />

              {disabled ? (
                <Button.Group fluid>
                  <Button primary color="green" onClick={() => toggleEdit()}>
                    Editar
                  </Button>
                </Button.Group>
              ) : (
                <Button.Group fluid>
                  <Button
                    basic
                    color="grey"
                    loading={loading}
                    onClick={() => cancelEdit()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    primary
                    color="blue"
                    loading={loading}
                    onClick={() => updateUserInfo()}
                  >
                    Salvar
                  </Button>
                </Button.Group>
              )}
            </Form>
          </Segment>
        </Segment.Group>
      </Grid.Column>
    </>
  );
};

export default UserInformation;
