import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Form } from "semantic-ui-react";
import { MessageContext } from "#providers";
import MaskedInput from "react-text-mask";
import { ListUserLicences } from "#components/users";
import { AuxiliaryFunctions } from "#static/scripts";

interface InsertTreeParams {
  refresh: any;
  open: boolean;
  changeOpen: any;
  ref: any;
  changeLoading: any;
  submitVoucher: any;
}
const AddTime: React.FC<InsertTreeParams> = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const [messageContext, setMessageContext] = useContext(MessageContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.changeLoading(loading);
  }, [loading]);

  const defaultState = {
    voucherCode: "TIME",
    voucherType: "DIAS_ACRESCENTAR_LICENCA_EXISTENTE",
    identificationType: "PREFIX",
    expirationDate: "",
    description: "",
    targetUserCode: "",
    targetLicenceCode: "",
    licenceDays: "",
    numberOfGeneralUses: 1,
    numberOfUsesPerUser: 1,
    percentualDiscount: "",
    integerDiscount: "",
    minimumPurchaseValue: "",
    minimumLicencesPerPurchase: "",
    maximumLicencesPerPurchase: "",
    licencesNumber: 1,
    totalValue: "",
  };

  const [voucherType, setVoucherType] = useState([
    {
      key: "PREFIX",
      text: "Prefixo + Aleatório (ex:'MATANATIVA5D13')",
      value: "PREFIX",
    },
    { key: "RANDOM", text: "Aleatório (ex:'5D13...')", value: "RANDOM" },
    { key: "MANUAL", text: "Código inteiro manual", value: "MANUAL" },
  ]);

  const [values, setValues] = useState(defaultState);
  const [enabledField, setEnabledFields] = useState({
    useMinimumPurchaseValue: false,
    discountType: "PERCENT",
    useTargetUser: false,
    useMinimumLicencesPerPurchase: false,
    useMaximumLicencesPerPurchase: false,
    useDescription: false,
  });

  const handleFormChange = (field, data) => {
    if (data.checked !== undefined)
      setValues({ ...values, [field]: data.checked });
    else setValues({ ...values, [field]: data.value });
  };

  const handleEnableFieldsChange = (field, data) => {
    if (data.checked !== undefined)
      setEnabledFields({ ...enabledField, [field]: data.checked });
  };

  const handleRadioChange = (field, data) => {
    setEnabledFields({ ...enabledField, [field]: data });
  };

  useImperativeHandle(ref, () => ({
    submit() {
      // @ts-ignore
      formRef.current.click();
    },
  }));

  const licenceCallback = (callback) => {
    setValues({ ...values, targetLicenceCode: callback.codigolicenca });
  };

  const createAddTimeVoucher = (e) => {
    e.preventDefault();
    let submitValues = {
      type: "DIAS_ACRESCENTAR_LICENCA_EXISTENTE",
      totalValue: parseFloat(values.totalValue.toString().replace(",", ".")),
      daysLicence: values.licenceDays,
      ...(values.identificationType === "PREFIX" && {
        codeCouponPrefix: values.voucherCode.toUpperCase(),
      }),
      ...(values.identificationType === "MANUAL" && {
        codeCoupon: values.voucherCode.toUpperCase(),
      }),
      expiresAt: AuxiliaryFunctions.convertDate(values.expirationDate),
      individualUseLimit: values.numberOfUsesPerUser,
      generalUseLimit: values.numberOfUsesPerUser,
      ...(enabledField.useDescription && { description: values.description }),
      userEmail: values.targetUserCode,
      codeLicence: values.targetLicenceCode,
    };
    props.submitVoucher(submitValues);
    setValues(defaultState);
  };

  return (
    <>
      <Form onSubmit={createAddTimeVoucher} className={"voucherForm"}>
        <Form.Group>
          <Form.Input
            width={3}
            label={"Identificador"}
            value={values.voucherCode}
            disabled={values.identificationType === "RANDOM"}
            required={values.identificationType !== "RANDOM"}
            onChange={(e, value) => handleFormChange("voucherCode", value)}
          />
          <Form.Select
            width={6}
            value={values.identificationType}
            onChange={(e, value) =>
              handleFormChange("identificationType", value)
            }
            label={"Identificador do voucher"}
            options={voucherType}
          />
          <Form.Input
            width={4}
            label="Valor Fixo"
            value={values.totalValue}
            type="number"
            required
            onChange={(e, value) => handleFormChange("totalValue", value)}
          />
          <Form.Input
            width={3}
            type="number"
            label="Dias Extras na licença"
            value={values.licenceDays}
            required
            onChange={(e, value) => handleFormChange("licenceDays", value)}
          />
        </Form.Group>

        <Form.Group widths={"equal"}>
          <Form.Input
            label={"Data Expiração do Voucher"}
            required
            input={
              <MaskedInput
                required
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                placeholder={"DD/MM/YYYY"}
                name="birthdate"
                value={values.expirationDate}
                autoComplete="none"
                onChange={(e) =>
                  setValues({ ...values, expirationDate: e.target.value })
                }
              />
            }
          />

          <Form.Input
            label={"Usos por usuário"}
            value={values.numberOfUsesPerUser}
            required
            onChange={(e, value) =>
              handleFormChange("numberOfUsesPerUser", value)
            }
          />
        </Form.Group>

        <Form.Group widths={"equal"}>
          <Form.Input
            type={"email"}
            label="Email do usuário"
            required
            value={values.targetUserCode}
            onChange={(e, value) => handleFormChange("targetUserCode", value)}
          />
          <ListUserLicences
            licenceCallback={licenceCallback}
            userEmail={values.targetUserCode}
          />
          <Form.Input
            label="Código da licença (Ex: 6CWP8ERN)"
            required
            value={values.targetLicenceCode}
            onChange={(e, value) =>
              handleFormChange("targetLicenceCode", value)
            }
          />
        </Form.Group>
        <Form.TextArea
          label={
            <Form.Checkbox
              disabled={false}
              checked={enabledField.useDescription}
              required={enabledField.useDescription}
              onChange={(e, value) =>
                handleEnableFieldsChange("useDescription", value)
              }
              label={"Descrição"}
            />
          }
          required={enabledField.useDescription}
          value={values.description}
          readOnly={!enabledField.useDescription}
          onChange={(e, value) => handleFormChange("description", value)}
        />
        <button hidden type={"submit"} ref={formRef} />
      </Form>
    </>
  );
});

export default AddTime;
