import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Container, Form, Label, Modal } from "semantic-ui-react";
import { MessageContext } from "#providers";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import AG_GRID_LOCALE_PT from "#static/mockups/agTranslations";
import { NumberViewer } from "#components/gridComponents";
import { useSelector } from "react-redux";
import { LicencesService } from "#services";
import { colors } from "#static/json";

interface params {
  licenceCallback: any;
  userEmail: string;
}
const ListUserLicences: React.FC<params> = forwardRef((props, ref) => {
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));

  const [messageContext, setMessageContext] = useContext(MessageContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const formRef = useRef();
  const [rowData, setRowData] = useState(null);

  const [columns, setColumns] = useState({
    codigolicenca: "Código licença",
    periododias: "Período (dias)",
    statusatual: "Status",
    createdat: "Criada em",
    activatedat: "Ativada em",
    expiresat: "Expira em",
  });

  const columnsDefinitions = Object.keys(columns).map((column) => {
    return {
      field: column,
      headerName: columns[column],
    };
  });

  const [columnsChecked, setColumnsChecked] = useState({});
  const [columnDefs, setColumnDefs] = useState(columnsDefinitions);

  // Change status cell with custom tag color
  const addCustomStatusLabel = (params: any) => {
    if (
      params.value == undefined ||
      params.value == "" ||
      params.value == null
    ) {
      return params.value;
    }
    const colorsInfo = {
      EXPIRED: "red",
      ACTIVATED: "green",
      SUSPENDED: "blue",
      BLOCKED: "black",
      NOT_YET_ACTIVATED: "yellow",
    };
    const colorsNames = {
      EXPIRED: "EXPIRADA",
      ACTIVATED: "ATIVADA",
      SUSPENDED: "SUSPENSA",
      BLOCKED: "BLOQUEADA",
      NOT_YET_ACTIVATED: "NÃO ATIVADA",
    };
    const getStatusColor = (type: any): any =>
      colorsInfo[type] != undefined && colorsInfo[type] != ""
        ? colorsInfo[type]
        : "yellow";
    const getStatusName = (type: any): any =>
      colorsNames[type] != undefined && colorsNames[type] != ""
        ? colorsNames[type]
        : "NÃO ATIVADA";

    return (
      <span>
        <Label color={getStatusColor(params.value)} horizontal>
          {getStatusName(params.value)}
        </Label>
      </span>
    );
  };

  columnDefs[2]["cellRenderer"] = addCustomStatusLabel;

  useEffect(() => {
    if (open) {
      if (
        props.userEmail != "" &&
        props.userEmail != null &&
        props.userEmail != undefined
      ) {
        LicencesService.getLicencesByEmail({
          userEmail: props.userEmail,
        })
          .then((res) => {
            setRowData(res.content.licences);
          })
          .catch((err) => {
            setMessageContext({
              id: 1,
              title: "Erro",
              description:
                "Houve um erro ao listar licenças do cliente. Contate um dev.",
              backgroundColor: colors.error,
              icon: "exclamation circle",
            });
          });
      } else {
        setMessageContext({
          id: 1,
          title: "Erro",
          description:
            "Escreva o email do cliente no campo anterior para listar as licenças deste.",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
        setOpen(false);
      }
    }
  }, [open]);

  // grid funcions
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const setLicenceCode = () => {
    let selectedLicence = gridApi.getSelectedRows();
    if (selectedLicence.length !== 0) {
      props.licenceCallback(selectedLicence[0]);
      setOpen(false);
    }
  };

  return (
    <>
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size={"large"}
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={
          <Form.Button
            onClick={(e) => {
              e.preventDefault();
            }}
            label="Selecione uma licença:"
            content={"Buscar licenças do usuário"}
            disabled={
              !(
                props.userEmail != "" &&
                props.userEmail != null &&
                props.userEmail != undefined
              )
            }
          />
        }
        open={open}
      >
        <Modal.Header>Seleção de Licença</Modal.Header>
        <Modal.Content>
          <span className="text__label__form" style={{ paddingTop: "15px" }}>
            Selecione uma das licenças do cliente abaixo para limitar o uso do
            voucher de tempo extra:
          </span>
          <Container
            className={"importTableSize"}
            style={{ width: "100%", height: "50vh", marginTop: "10px" }}
          >
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className={
                theme.darkMode ? "ag-theme-balham-dark" : "ag-theme-balham"
              }
            >
              <AgGridReact
                localeText={AG_GRID_LOCALE_PT}
                suppressDragLeaveHidesColumns={true}
                rowData={rowData}
                onCellDoubleClicked={() => setLicenceCode()}
                defaultColDef={{
                  flex: 1,
                  resizable: true,
                  sortable: true,
                }}
                rowSelection={"single"}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                enableCellTextSelection
              >
                <AgGridColumn
                  cellRendererFramework={NumberViewer}
                  field={"codigolicenca"}
                  headerName={"Código Licença"}
                />

                <AgGridColumn
                  cellRendererFramework={NumberViewer}
                  field={"periododias"}
                  headerName={"Período dias"}
                />

                <AgGridColumn
                  cellRendererFramework={NumberViewer}
                  field={"statusatual"}
                  headerName={"Status"}
                />

                <AgGridColumn
                  cellRendererFramework={NumberViewer}
                  field={"activatedat"}
                  headerName={"Ativada em"}
                />

                <AgGridColumn
                  cellRendererFramework={NumberViewer}
                  field={"expiresat"}
                  headerName={"Expira em"}
                />
              </AgGridReact>
            </div>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            className={"buttonCancel"}
            loading={loading}
            content={"Fechar"}
            onClick={() => setOpen(false)}
          />
          <Button
            loading={loading}
            content={"Selecionar"}
            onClick={() => setLicenceCode()}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
});

export default ListUserLicences;
