import Service from "./service";

export default class PaymentsService extends Service {
  private static get = async (params) => {
    try {
      let page = params.page;
      const token = Service.token();
      let searchString;
      if (params.payload.hasOwnProperty("searchKey"))
        searchString = `limit=100&page=${page}&search=[${params.payload.searchKey}]`;
      else searchString = `limit=100&page=${page}`;
      const res = await fetch(
        `${Service.getAPIData()}/admin/transactions?${searchString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getPaymentsId = async (params) => {
    try {
      const token = Service.token();
      const userId = params.userId;
      let searchString = `userId=${userId}`;
      const res = await fetch(
        `${Service.getAPIData()}/admin/transactions?${searchString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getTransactionInfo = async (params) => {
    try {
    let token = Service.token();
    let {userId} = params;
        const res = await fetch(
          `${Service.getAPIData()}/admin/transactions/users/${userId}/info`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          return Promise.resolve(res.json());
        } else {
          return Promise.reject(res.json());
        }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getTransactions = async (params) => {
    return await PaymentsService.get(params);
  };

  static getTransactionsById = async (params) => {
    return await PaymentsService.getPaymentsId(params);
  };

  static getPreviousTransactionsData = async (params) => {
    return await PaymentsService.getTransactionInfo(params);
  };
}
