import React, {Component} from "react";

import {createStore} from "redux";
import {Provider} from "react-redux";
import reducers from "./controllers/store";
// Importing the global styles
import "#static/css/fonts.css";
import "semantic-ui-css/semantic.min.css";
import "#static/css/style.css";
import "#static/css/components.css";
import "#static/css/login.css";
import "#static/css/grid.scss";

import {I18nextProvider} from "react-i18next";
import {i18n} from "#providers";

import {disableReactDevTools} from "./static/scripts";
//Importing the router
import Router from "./controllers/router";
import MessageProvider from "#providers/message";
import GlobalContextProvider from "#providers/globalContext";


const store = createStore(reducers);

class App extends Component {
  componentDidMount() {
    disableReactDevTools();
  }
  render() {
    return (
      <div className="App">
        <React.Suspense fallback={<div> Loading... </div>}>
          <Provider store={store}>
            <MessageProvider>
              <GlobalContextProvider>
                <I18nextProvider i18n={i18n}>
                  <Router />
                </I18nextProvider>
              </GlobalContextProvider>
            </MessageProvider>
          </Provider>
        </React.Suspense>
      </div>
    );
  }
}

export default App;
