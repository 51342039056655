/** Concerning user logged as admin */
export function user(
  state: { data: any; permissions: any } = null,
  action: { type: string; payload: { data: any; permissions: any } }
) {
  switch (action.type) {
    case "setUser":
      return action.payload;
    case "clearUser":
      return null;
    default:
      return state;
  }
}
