import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Form, Modal, Segment } from "semantic-ui-react";
import { MessageContext } from "#providers";
import MaskedInput from "react-text-mask";
import { LicencesService, PaymentsService } from "#services";
import { colors } from "#static/json";
import testaCPF from "./validation/ValidateCPF";
import validarCNPJ from "./validation/ValidateCNPJ";

interface FillParams {
  user: any;
  refreshLicences: any;
}

const DirectLicence: React.FC<FillParams> = (props) => {
  const defaultState = {
    licenceDays: "",
    totalValue: "0",
    targetUser: "",
    nome: "",
    sobrenome: "",
    email: "",
    ie: "",
    cep: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    complemento: "",
    telefone: "",
    observation: "",
  };

  const paymentOptions = [
    { key: "PIX", text: "Pix", value: "PIX" },
    { key: "BOLETO", text: "Boleto", value: "BOLETO" },
    {
      key: "CARTAO_CREDITO",
      text: "Cartão de crédito",
      value: "CARTAO_CREDITO",
    },
  ];

  const transactionHistoryDefault = {
    hasUserData: false,
    hasCPFData: false,
    hasCNPJData: false,
  };
  const transactionHistoryDataDefault = {
    userData: null,
    cpfPreviousTransaction: null,
    cnpjPreviousTransaction: null,
  };

  const checkedValuesDefault = { useCPF: "CPF", useCel: "CEL" };

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noteDetails, setNoteDetails] = useState(defaultState);
  const [checkedValues, setCheckedValues] = useState(checkedValuesDefault);
  const [cpf, setCPF] = useState("");
  const [cnpj, setCNPJ] = useState("");
  const [erroCPF, setErroCPF] = useState(false);
  const [erroCNPJ, setErroCNPJ] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("PIX");

  const [messageContext, setMessageContext] = useContext(MessageContext);

  const [transactionHistory, setTransactionHistory] = useState(
    transactionHistoryDefault
  );
  const [transactionHistoryData, setTransactionHistoryData] = useState(
    transactionHistoryDataDefault
  );
  const [transactionHistoryErrorMessage, setTransactionHistoryErrorMessage] =
    useState("");

  const [transactionHistoryMessage, setTransactionHistoryMessage] =
    useState("");

  const [loadingTransactionInfo, setLoadingTransactionInfo] = useState(false);

  const noteRef = useRef();

  const onOpen = () => {
    const assertInputValue = (value: any) => {
      if (value === null) return "";
      else return value;
    };

    setOpen(true);
    setNoteDetails({
      ...noteDetails,
      targetUser: props.user.email,
      nome: props.user.nome,
      telefone: assertInputValue(props.user.telefone),
      cep: assertInputValue(props.user.cep),
      endereco: assertInputValue(props.user.endereco),
      cidade: assertInputValue(props.user.cidade),
      email: assertInputValue(props.user.email),
    });
    setCPF(assertInputValue(props.user.cpf));
  };

  useEffect(() => {
    if (transactionHistoryErrorMessage != "") {
      setTimeout(() => {
        setTransactionHistoryErrorMessage("");
      }, 7000);
    }
  }, [transactionHistoryErrorMessage]);

  useEffect(() => {
    if (transactionHistoryMessage != "") {
      setTimeout(() => {
        setTransactionHistoryMessage("");
      }, 7000);
    }
  }, [transactionHistoryMessage]);

  const generatePayload = () => {
    return {
      transaction: {
        couponId: null,
        transactionValue: noteDetails.totalValue,
        finalTransactionValue: noteDetails.totalValue,
        paymentMethod:
          !isNaN(parseInt(noteDetails.totalValue)) &&
          parseInt(noteDetails.totalValue) > 0
            ? selectedPaymentMethod
            : "DOACAO",
        codeUserPaid: props.user.codusuario,
        products: [
          {
            value: noteDetails.totalValue,
            days: noteDetails.licenceDays,
            observation: noteDetails.observation,
          },
        ],
      },
      payerInfo: {
        nome: noteDetails.nome,
        sobrenome: noteDetails.sobrenome,
        email: noteDetails.email,
        ie: noteDetails.ie,
        cep: noteDetails.cep,
        endereco: noteDetails.endereco,
        numero: noteDetails.numero,
        bairro: noteDetails.bairro,
        cidade: noteDetails.cidade,
        estado: noteDetails.estado,
        complemento: noteDetails.complemento,
        telefone: noteDetails.telefone,
        ...(checkedValues.useCPF === "CPF" && { cpf: cpf }),
        ...(checkedValues.useCPF === "CNPJ" && { cnpj: cnpj }),
      },
    };
  };

  const handleFormChange = (field: string, data: any) => {
    if (data.checked !== undefined)
      setNoteDetails({ ...noteDetails, [field]: data.checked });
    else setNoteDetails({ ...noteDetails, [field]: data.value });
  };

  const handleRadioChange = (field: any, data: any) => {
    if (field == "useCPF" && data == "CPF") {
      setCNPJ("");
      setNoteDetails({ ...noteDetails, ["ie"]: "" });
    }
    if (field == "useCPF" && data == "CNPJ") {
      setCPF("");
      setNoteDetails({ ...noteDetails, ["sobrenome"]: "" });
    }

    setCheckedValues({ ...checkedValues, [field]: data });
  };

  const isFieldEmpty = (value) =>
    !(value != undefined && value != null && value != "");

  const noteSubmit = () => {
    // @ts-ignore
    noteRef.current.click();

    if (checkedValues.useCPF === "CPF") {
      if (!testaCPF(cpf.replace(/[^\w\s]/gi, ""))) {
        //remove symbols
        setErroCPF(true);
        return;
      } else setErroCPF(false);
    } else if (checkedValues.useCPF === "CNPJ") {
      if (!validarCNPJ(cnpj.replace(/[^\w\s]/gi, ""))) {
        //remove symbols
        setErroCNPJ(true);
        return;
      } else setErroCNPJ(false);
    }
    // check if masked input fields are filled (alert shows up but won't stop the function)
    if (
      isFieldEmpty(noteDetails.telefone) ||
      isFieldEmpty(noteDetails.cep) ||
      isFieldEmpty(noteDetails.estado) ||
      isFieldEmpty(noteDetails.bairro) ||
      isFieldEmpty(noteDetails.targetUser) ||
      isFieldEmpty(noteDetails.licenceDays)
    ) {
      return;
    }

    const payload = generatePayload();

    setLoading(true);
    LicencesService.createSingleLicence(payload)
      .then((res) => {
        setMessageContext({
          id: 1,
          title: "Sucesso",
          description: "Licença criada com sucesso",
          backgroundColor: colors.success,
          icon: "check",
        });
        setLoading(false);
        props.refreshLicences();
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setMessageContext({
          id: 1,
          title: "Erro",
          description: "Erro ao criar licença",
          backgroundColor: colors.error,
          icon: "exclamation outline",
        });
      });
  };

  const getUserOldTransactionInfo = () => {
    setLoadingTransactionInfo(true);
    PaymentsService.getPreviousTransactionsData({
      userId: props.user.codusuario,
    })
      .then(({ content }: any) => {
        const paymentHistory: {
          hasUserData: boolean;
          hasCPFData: boolean;
          hasCNPJData: boolean;
        } = content.history;
        setTransactionHistory(paymentHistory);
        const previousTransactionData = {
          userData: content.transactionData.user,
          cpfPreviousTransaction: content.transactionData.cpf,
          cnpjPreviousTransaction: content.transactionData.cnpj,
        };
        setTransactionHistoryData(previousTransactionData);

        setLoadingTransactionInfo(false);
        handleTransactionHistory(paymentHistory, previousTransactionData);
      })
      .catch((err) => {
        setMessageContext({
          id: 1,
          title: "Erro",
          description:
            "Erro ao obter dados nota fiscal. Contate o suporte por gentileza.",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
      });
  };

  const handleTransactionHistory = (paymentHistory: any, paymentData: any) => {
    if (!paymentHistory.hasCPFData && !paymentHistory.hasCNPJData) {
      setTransactionHistoryErrorMessage(
        `Não foi encontrado pagamento anterior sendo ${
          checkedValues.useCPF == "CPF" ? "pessoa física" : "pessoa jurídica"
        }. Apenas dados de cadastro foram obtidos.`
      );
      setNoteDetails({ ...noteDetails, ...paymentData.userData });
    } else {
      if (checkedValues.useCPF == "CPF") {
        if (!paymentHistory.hasCPFData) {
          setTransactionHistoryErrorMessage(
            `Não foi encontrado pagamento anterior sendo pessoa física.`
          );
        } else {
          setTransactionHistoryMessage(
            `Foram encontrados pagamentos anteriores sendo pessoa física. Auto completando os campos de nota fiscal.`
          );

          setCPF(paymentData.cpfPreviousTransaction["cpf"]);

          let processedPayload = JSON.parse(
            JSON.stringify(paymentData.cpfPreviousTransaction)
          );
          delete processedPayload.cpf;
          delete processedPayload.cnpj;
          setNoteDetails({ ...noteDetails, ...processedPayload });
        }
      } else if (checkedValues.useCPF == "CNPJ") {
        if (!paymentHistory.hasCNPJData) {
          setTransactionHistoryErrorMessage(
            `Não foi encontrado pagamento anterior sendo pessoa jurídica.`
          );
        } else {
          setTransactionHistoryMessage(
            `Foram encontrados pagamentos anteriores sendo pessoa jurídica. Auto completando os campos de nota fiscal.`
          );

          setCNPJ(paymentData.cnpjPreviousTransaction["cnpj"]);

          let processedPayload = JSON.parse(
            JSON.stringify(paymentData.cnpjPreviousTransaction)
          );
          delete processedPayload.cpf;
          delete processedPayload.cnpj;

          setNoteDetails({ ...noteDetails, ...processedPayload });
        }
      }
    }
  };

  return (
    <>
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size={"large"}
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => onOpen()}
        trigger={
          <Button
            icon="add"
            className="button--primary"
            content="Adicionar Licença"
          />
        }
        open={open}
      >
        <Modal.Header>Adicionar Licença</Modal.Header>
        <Modal.Content>
          <section style={{ marginBottom: "10px" }}>
            <Form>
              <Segment style={{ backgroundColor: "#EBF1EB" }}>
                <Form.Group inline style={{ marginBottom: "4px" }}>
                  <span className="text__label">
                    <b>Não é a primeira compra do usuário no Mata Nativa? </b>
                    <br /> Clique no botão ao lado para buscar os dados do
                    último pagamento de acordo com o documento selecionado.
                  </span>
                  <Button
                    className="buttonSecondary__transparent"
                    content={"Buscar dados"}
                    onClick={() => getUserOldTransactionInfo()}
                    loading={loadingTransactionInfo}
                    disabled={loadingTransactionInfo}
                    style={{
                      minWidth: "25%",
                      marginLeft: "16px",
                    }}
                  />
                </Form.Group>

                {transactionHistoryErrorMessage != "" && (
                  <span
                    className="text__label"
                    style={{
                      color: "#db2828",
                      marginTop: "25px",
                    }}
                  >
                    {transactionHistoryErrorMessage}
                  </span>
                )}
                {transactionHistoryMessage != "" && (
                  <span
                    className="text__label"
                    style={{
                      color: "#2f80ed",
                      marginTop: "25px",
                    }}
                  >
                    {transactionHistoryMessage}
                  </span>
                )}
              </Segment>
            </Form>
          </section>

          <Form onSubmit={(e) => e.preventDefault()}>
            <Segment>
              <span className="text__header__licence">
                Dados da Nota Fiscal
              </span>
              <Form.Group
                inline
                style={{ marginTop: "1em", marginBottom: "2em" }}
              >
                <span
                  className="text__label__form labelRequiredIcon"
                  style={{ marginRight: "1em" }}
                >
                  <label
                    style={{
                      backgroundColor: "transparent",
                      paddingLeft: "2px",
                    }}
                  >
                    {" "}
                    Tipo de documento:{" "}
                  </label>
                </span>

                <Form.Radio
                  label="Pessoa Física"
                  value="CPF"
                  onClick={() => handleRadioChange("useCPF", "CPF")}
                  checked={checkedValues.useCPF === "CPF"}
                  className="radioLabel"
                />
                <Form.Radio
                  label="Pessoa Jurídica"
                  value="CNPJ"
                  onClick={() => handleRadioChange("useCPF", "CNPJ")}
                  checked={checkedValues.useCPF === "CNPJ"}
                  className="radioLabel"
                />
              </Form.Group>
              <Form.Group>
                {checkedValues.useCPF === "CPF" && (
                  <>
                    <Form.Input
                      required
                      width={10}
                      className="text__label__form"
                      label={"Nome"}
                      fluid
                      onChange={(e, value) => handleFormChange("nome", value)}
                      value={noteDetails.nome || ""}
                    />
                    <Form.Input
                      required
                      width={6}
                      className="text__label__form"
                      label={"Sobrenome"}
                      fluid
                      onChange={(e, value) =>
                        handleFormChange("sobrenome", value)
                      }
                      value={noteDetails.sobrenome || ""}
                    />
                  </>
                )}
                {checkedValues.useCPF === "CNPJ" && (
                  <>
                    <Form.Input
                      required
                      width={10}
                      className="text__label__form"
                      label={"Razão Social"}
                      fluid
                      onChange={(e, value) => handleFormChange("nome", value)}
                      value={noteDetails.nome || ""}
                    />
                  </>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Input
                  required
                  width={10}
                  type="email"
                  className="text__label__form"
                  label={"Email"}
                  fluid
                  onChange={(e, value) => handleFormChange("email", value)}
                  value={noteDetails.email || ""}
                />
                <Form.Input
                  width={6}
                  label={
                    <>
                      <Form.Group style={{ marginBottom: "3px" }}>
                        <Form.Radio
                          label="Celular"
                          required
                          value="CEL"
                          onClick={() => handleRadioChange("useCel", "CEL")}
                          checked={checkedValues.useCel === "CEL"}
                        />
                        <Form.Radio
                          label="Fixo"
                          value="FIXO"
                          required
                          onClick={() => handleRadioChange("useCel", "FIXO")}
                          checked={checkedValues.useCel === "FIXO"}
                        />
                      </Form.Group>
                    </>
                  }
                  required
                  input={
                    checkedValues.useCel === "CEL" ? (
                      <MaskedInput
                        required
                        mask={[
                          "(",
                          /[0-9]/,
                          /[0-9]/,
                          ")",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          "-",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        name="phone"
                        value={noteDetails.telefone}
                        autoComplete="none"
                        onChange={(e) => {
                          setNoteDetails({
                            ...noteDetails,
                            telefone: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <MaskedInput
                        required
                        mask={[
                          "(",
                          /[0-9]/,
                          /[0-9]/,
                          ")",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          "-",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        name="phone"
                        value={noteDetails.telefone}
                        autoComplete="none"
                        onChange={(e, value) =>
                          setNoteDetails({
                            ...noteDetails,
                            telefone: e.target.value,
                          })
                        }
                      />
                    )
                  }
                />
              </Form.Group>
              <Form.Group widths={"equal"}>
                {checkedValues.useCPF === "CPF" && (
                  <Form.Input
                    label={"CPF"}
                    input={
                      <MaskedInput
                        required
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          ".",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          ".",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          "-",
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        name="cpf"
                        value={cpf}
                        autoComplete="none"
                        onChange={(e) => {
                          setCPF(e.target.value);
                        }}
                      />
                    }
                    value={cpf}
                    error={
                      erroCPF && {
                        content: "CPF inválido.",
                        pointing: "below",
                      }
                    }
                    required
                  />
                )}
                {checkedValues.useCPF === "CNPJ" && (
                  <>
                    <Form.Input
                      label={"CNPJ"}
                      input={
                        <MaskedInput
                          required
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            ".",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            ".",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            "/",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            "-",
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                          name="cnpj"
                          value={cnpj}
                          autoComplete="none"
                          onChange={(e, value) => setCNPJ(e.target.value)}
                        />
                      }
                      value={cnpj}
                      error={
                        erroCNPJ && {
                          content: "CNPJ inválido.",
                          pointing: "below",
                        }
                      }
                      required
                    />
                    <Form.Input
                      className="text__label__form"
                      label={"Inscrição Estadual (IE)"}
                      fluid
                      onChange={(e, value) => handleFormChange("ie", value)}
                      value={noteDetails.ie || ""}
                    />
                  </>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Input
                  width={"6"}
                  className="text__label__form"
                  label={"Rua"}
                  required
                  fluid
                  onChange={(e, value) => handleFormChange("endereco", value)}
                  value={noteDetails.endereco || ""}
                />
                <Form.Input
                  width={"2"}
                  className="text__label__form"
                  label={"Número"}
                  required
                  fluid
                  onChange={(e, value) => handleFormChange("numero", value)}
                  value={noteDetails.numero || ""}
                />
                <Form.Input
                  width={"4"}
                  className="text__label__form"
                  label={"Complemento"}
                  fluid
                  onChange={(e, value) =>
                    handleFormChange("complemento", value)
                  }
                  value={noteDetails.complemento || ""}
                />
                <Form.Input
                  className="text__label__form"
                  width={"4"}
                  label={"Bairro"}
                  required
                  fluid
                  onChange={(e, value) => handleFormChange("bairro", value)}
                  value={noteDetails.bairro || ""}
                />
              </Form.Group>

              <Form.Group>
                <Form.Input
                  width={6}
                  className="text__label__form"
                  label={"Cidade"}
                  required
                  fluid
                  onChange={(e, value) => handleFormChange("cidade", value)}
                  value={noteDetails.cidade || ""}
                />
                <Form.Input
                  width={5}
                  maxLength={3}
                  className="text__label__form"
                  label={"Estado (sigla)"}
                  required
                  input={
                    <MaskedInput
                      required
                      mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
                      name="state"
                      value={noteDetails.estado}
                      autoComplete="none"
                      onChange={(e) => {
                        setNoteDetails({
                          ...noteDetails,
                          estado: e.target.value,
                        });
                      }}
                    />
                  }
                  fluid
                  value={noteDetails.estado || ""}
                />
                <Form.Input
                  className="text__label__form"
                  width={5}
                  label={"CEP"}
                  input={
                    <MaskedInput
                      required
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      name="cep"
                      value={noteDetails.cep}
                      autoComplete="none"
                      onChange={(e, value) =>
                        setNoteDetails({ ...noteDetails, cep: e.target.value })
                      }
                    />
                  }
                  required
                  fluid
                />
              </Form.Group>
            </Segment>

            <Segment style={{ marginTop: "0.5em", marginBottom: "1em" }}>
              <span className="text__header__licence">
                Informações da Licença
              </span>
              <Form.Group fluid widths="equal" style={{ marginTop: "1em" }}>
                <Form.Input
                  className="text__label__form"
                  label={"Email Usuário de destino (Não editável)"}
                  fluid
                  editable={false}
                  readOnly
                  value={noteDetails.targetUser || ""}
                />
                <Form.Input
                  required
                  className="text__label__form"
                  label={"Dias da licença"}
                  fluid
                  number
                  onChange={(e, value) =>
                    handleFormChange("licenceDays", value)
                  }
                  value={noteDetails.licenceDays || ""}
                />
                <Form.Input
                  width={4}
                  className="text__label__form"
                  label={"Valor total"}
                  type={"number"}
                  fluid
                  onChange={(e, value) => handleFormChange("totalValue", value)}
                  value={noteDetails.totalValue || ""}
                />
                {!isNaN(parseInt(noteDetails.totalValue)) &&
                  parseInt(noteDetails.totalValue) > 0 && (
                    <Form.Dropdown
                      required
                      className="text__label__form"
                      label={"Método de pagamento"}
                      options={paymentOptions}
                      fluid
                      onChange={(e, value) =>
                        setSelectedPaymentMethod(value.value as string)
                      }
                      value={selectedPaymentMethod || ""}
                    />
                  )}
              </Form.Group>

              <Form.Group fluid widths="equal">
                <Form.TextArea
                  className="text__label__form"
                  label={"Observação para licença"}
                  fluid
                  value={noteDetails.observation || ""}
                  onChange={(e, value) =>
                    handleFormChange("observation", value)
                  }
                  width={16}
                />
              </Form.Group>
            </Segment>
            <button ref={noteRef} hidden type={"submit"} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            className={"buttonCancel"}
            loading={loading}
            content={"Fechar"}
            onClick={() => setOpen(false)}
          />

          <>
            <Button
              content="Adicionar"
              loading={loading}
              //@ts-ignore
              onClick={() => noteSubmit()}
              positive
            />
          </>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DirectLicence;
