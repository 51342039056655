import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Label, Segment } from "semantic-ui-react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import AG_GRID_LOCALE_PT from "#static/mockups/agTranslations";
import { useSelector } from "react-redux";

interface params {
  user: any;
  rowData: any[];
}
const UserPayments: React.FC<params> = (props) => {
  const { t } = useTranslation();
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));

  const columns = {
    valorcompra: "Valor compra",
    valorcompratotal: "Valor total",
    status: "Status",
    codigocupom: "Cód. Cupom",
    paidat: "Data pgto.",
    metodopagamento: "Método pgto.",
    diaslicenca: "Dias licença",
    idpagamentoasaas: "Id Asas",
    nome: "Nome",
    sobrenome: "Sobrenome",
    email: "Email Nota Fiscal",
    emailproprietario: "Email Proprietário",
    cpf: "CPF",
    cnpj: "CNPJ",
    ie: "IE",
    cep: "CEP",
    endereco: "Endereço",
    numero: "Número",
    bairro: "Bairro",
    cidade: "Cidade",
    estado: "Estado",
    complemento: "Complemento",
    telefone: "Telefone",
    createdat: "Criado",
    updatedat: "Atualizado",
    codtransacaofinanceira: "Código da transação",
  };

  const columnsDefinitions = Object.keys(columns).map((column) => {
    return {
      field: column,
      headerName: columns[column],
    };
  });

  const [columnDefs, setColumnDefs] = useState(columnsDefinitions);
  const [columnsChecked, setColumnsChecked] = useState({});

  // Change status cell with custom tag color
  const addCustomStatusLabel = (params: any) => {
    if (
      params.value == undefined ||
      params.value == "" ||
      params.value == null
    ) {
      return params.value;
    }
    const colorsInfo = {
      PENDING: "yellow", // Pagamento em espera
      CONFIRMED: "green", // Pagamento confirmado
      REFUND_REQUESTED: "red", // Pagamento estornado
      CANCELED: "gray", // Pagamento cancelado
    };
    const colorsNames = {
      PENDING: "AGUARDANDO", // Pagamento em espera
      CONFIRMED: "CONFIRMADO", // Pagamento confirmado
      REFUND_REQUESTED: "ESTORNADO", // Pagamento estornado
      CANCELED: "CANCELADO", // Pagamento cancelado
    };
    const getStatusColor = (type: any): any =>
      colorsInfo[type] != undefined && colorsInfo[type] != ""
        ? colorsInfo[type]
        : "";
    const getStatusName = (type: any): any =>
      colorsNames[type] != undefined && colorsNames[type] != ""
        ? colorsNames[type]
        : "";

    return (
      <span>
        <Label color={getStatusColor(params.value)} horizontal>
          {getStatusName(params.value)}
        </Label>
      </span>
    );
  };

  columnDefs[2]["cellRenderer"] = addCustomStatusLabel;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (props.rowData) {
      setRowData(props.rowData);
    }
  }, [props.rowData]);

  // grid funcions
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  /**
   * filter the column according to the selected checkbox
   * @param key key id
   */
  const columnFilter = (key: string) => {
    if (columnsChecked[key] === true) {
      gridColumnApi.setColumnVisible(key, false);
    }
    if (columnsChecked[key] === false) {
      gridColumnApi.setColumnVisible(key, true);
    }
    setColumnsChecked({ ...columnsChecked, [key]: !columnsChecked[key] });
  };

  return (
    <>
      <span className="text__form__title">Lista de Transações</span>
      <div
        style={{
          paddingTop: "5px",
        }}
      >
        <span className="text__secondary">
          {`Total: ${rowData ? rowData.length : 0}`}
        </span>
      </div>
      <div
        style={{
          overflow: "auto",
          maxHeight: "40vh",
          marginBottom: "50px",
          marginTop: "10px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
      >
        <Container
          fluid
          style={{ width: "100%", overflow: "auto", height: "32vh" }}
        >
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className={
              theme.darkMode ? "ag-theme-balham-dark" : "ag-theme-balham"
            }
          >
            <AgGridReact
              localeText={AG_GRID_LOCALE_PT}
              suppressDragLeaveHidesColumns={true}
              rowData={rowData}
              defaultColDef={{
                editable: false,
                resizable: true,
                sortable: true,
              }}
              rowSelection={"single"}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              enableCellTextSelection
            >
              {Object.entries(columns).map((item, index) => {
                return (
                  <AgGridColumn
                    key={index}
                    field={item[0].toString()}
                    headerName={item[1] as string}
                    headerTooltip={item[1]}
                    tooltipField={item[0]}
                  />
                );
              })}
            </AgGridReact>
          </div>
        </Container>
      </div>
    </>
  );
};

export default UserPayments;
