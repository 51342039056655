import React, {memo, useEffect, useState} from "react";

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!

  return !/[a-zA-Z]/.test(str);
}

const NumberViewer = (props) =>{

  const [value, setValue] = useState(!!props.value ? props.value.toString().replace(/,/g, '.') : "");
  const [floatText, setFloatText] = useState('left');

  useEffect(()=>{
    if(!!props.value){
      if(isNumeric(props.value.toString())){
        setValue(props.value.toString().replace(/\./g, ','))
        setFloatText("right")
      }
      else{
        setValue(props.value.toString().replace(/\./g, ',').replace('***', ''))
        setFloatText("left")
      }
    }
    else{
      setValue("")
    }
  },[props.value])


  return (
      // @ts-ignore
      <div style={{float: floatText}}>
        {value}
      </div>
  );
}

export default memo(NumberViewer)
