import React, {memo} from "react";


const LinkViewer = (props) =>{
  const linkURL = (process.env.PROJECT_TYPE === 'stage' || process.env.PROJECT_TYPE === 'DEV') ? "http://mata-nativa-interface.herokuapp.com/#/?voucherCode=" : "https://web.matanativa.com.br/#/?voucherCode="

  return (
      <div>
        {props.node.data.tipo !== 'DESCONTO_NO_VALOR' && linkURL+props.node.data.codigocupom}
      </div>
  );
}

export default memo(LinkViewer)
