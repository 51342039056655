import React, {useContext, useEffect} from "react";
import {Sidebar} from "semantic-ui-react";
import {VerticalSidebarOpenProject, VerticalSidebarOpenProjectCollapsed,} from "#components/sidebarMenu";
import {GlobalContext} from "#providers/globalContext";

/* Main Component */
let ProjectSidebar = (props) => {
  const [globalContext, dispatch] = useContext(GlobalContext);

  const toggleSidebar = () => {
    dispatch({ type: "setSidebarToggle" });
  };

  useEffect(() => {}, [globalContext]);

  return (
    <>
      <div className={"sidebarSpacingData"}>
        <Sidebar.Pushable secondary className={"background"}>
          <>
            <VerticalSidebarOpenProject
              sidebarVisible={globalContext.colapsed}
              toggleSidebar={toggleSidebar}
            />
            {!globalContext.colapsed && (
              <VerticalSidebarOpenProjectCollapsed
                sidebarVisible={globalContext.colapsed}
                toggleSidebar={toggleSidebar}
              />
            )}
          </>

          <Sidebar.Pusher
            className={
              globalContext.colapsed
                ? "openSidebar mainContentBackground"
                : "closedSidebar mainContentBackground"
            }
          >
            {React.cloneElement(props.children, { test: "testando" })}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </>
  );
};

export default ProjectSidebar;
