import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Divider, Image, Menu, Popup } from "semantic-ui-react";
import { userImage } from "#static/img";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Cookie from "js-cookie";
import { AuxiliaryFunctions } from "#static/scripts";

interface props {
  openPayment?: any;
}

/* Main Component */
let ProfilePopup: React.FC<props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("home");
  const [open, setOpen] = useState(false);

  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));
  const { userData } = useSelector((state: any) => ({ userData: state.user }));
  const dispatch = useDispatch();

  const logout = () => {
    Cookie.remove("aTkn", { domain: process.env.REACT_APP_DOMAIN });
    window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`);
  };

  const closeProject = () => {
    dispatch({ type: "clearProject" });
    navigate("/");
  };

  const toggleDarkMode = () => {
    if (theme.darkMode) dispatch({ type: "setLightMode" });
    else dispatch({ type: "setDarkMode" });
  };

  return (
    <>
      <Popup
        hoverable
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        open={open}
        trigger={
          <Menu.Item className={"profileIcon"}>
            <Image
              className={"imageProfile"}
              circular
              src={
                userData && userData.data && userData.data.avatar
                  ? /data:image/i.test(userData.data.avatar)
                    ? userData.data.avatar
                    : `${process.env.REACT_APP_AWS_BUCKET_USERS_AVATAR}/${userData.data.avatar}`
                  : userImage
              }
            />
          </Menu.Item>
        }
        content={
          <Card className={"profileCard"}>
            <Card.Content>
              <Image
                floated="left"
                size="mini"
                src={
                  userData && userData.data && userData.data.avatar
                    ? /data:image/i.test(userData.data.avatar)
                      ? userData.data.avatar
                      : `${process.env.REACT_APP_AWS_BUCKET_USERS_AVATAR}/${userData.data.avatar}`
                    : userImage
                }
                circular
                className={"imageProfile popupMenu"}
              />
              <Card.Header>
                {userData && AuxiliaryFunctions.formatName(userData.data.nome)}
              </Card.Header>
              <Card.Meta>{userData && userData.data.email}</Card.Meta>

              <Card.Description>
                <Divider style={{ marginBottom: "5px" }} />
                <Button
                  basic
                  icon={theme.darkMode ? "moon" : "sun outline"}
                  onClick={() => toggleDarkMode()}
                  className={"profileButton"}
                  secondary
                  fluid
                  content={"Dark Mode"}
                />
                <Button
                  basic
                  icon={"sign-out"}
                  onClick={() => logout()}
                  className={"profileButton"}
                  secondary
                  fluid
                  content={"Sair"}
                />
              </Card.Description>
            </Card.Content>
          </Card>
        }
      />
    </>
  );
};

export default ProfilePopup;
