import Service from "./service";

export default class CalculosService extends Service {
  static MAX_NUMBER_BY_PAGE = 100;

  // Empresas

  private static list = async (params) => {
    try {
      const page = params.page ? params.page : 1;
      const token = Service.token();
      const limitAndPageString = `limit=${
        params.limit ? params.limit : CalculosService.MAX_NUMBER_BY_PAGE
      }&page=${page}`;

      let dateString = "";
      if (
        params.payload.hasOwnProperty("beginDate") &&
        params.payload.hasOwnProperty("endDate")
      )
        dateString = `&beginDate=${params.payload.beginDate}&endDate=${params.payload.endDate}`;

      let orderAndOrderByString = "";
      if (params.payload.order && params.payload.orderBy)
        orderAndOrderByString = `&order=${params.payload.order}&orderBy=${params.payload.orderBy}`;

      let userSearch = "";
      if (params.payload.user) userSearch = `&user=${params.payload.user}`;

      let tipoSearch = "";
      if (params.payload.tipo) tipoSearch = `&tipo=${params.payload.tipo}`;

      const res = await fetch(
        `${Service.getAPIData()}/admin/calculos?${limitAndPageString}${dateString}${orderAndOrderByString}${userSearch}${tipoSearch}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /* Public Calls */

  // Company

  static getCalculos = async (params) => {
    return await CalculosService.list(params);
  };
}
