export const currencies = [
  "AUD",
  "BRL",
  "CAD",
  "CZK",
  "DKK",
  "EUR",
  "HKD",
  "HUF",
  "ILS",
  "JPY",
  "MYR",
  "MXN",
  "TWD",
  "NZD",
  "NOK",
  "PHP",
  "PLN",
  "GBP",
  "RUB",
  "SGD",
  "SEK",
  "CHF",
  "THB",
  "USD",
];

export const currencySymbols = {
  AED: "د.إ",
  AUD: "$",
  BDT: "৳",
  BGN: "лв",
  BRL: "R$",
  CAD: "$",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥ /元",
  COP: "$",
  CZK: "Kč",
  DKK: "kr",
  EUR: "€",
  GBP: "£",
  GEL: "₾",
  HKD: "HK$",
  HRK: "kn",
  HUF: "ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  JPY: "¥",
  KES: "Ksh",
  KRW: "₩",
  LKR: "Rs",
  MAD: ".د.م",
  MXN: "$",
  MYR: "RM",
  NGN: "₦",
  NOK: "kr",
  NZD: "$",
  PEN: "S/.",
  PHP: "₱",
  PKR: "Rs",
  PLN: "zł",
  RON: "lei",
  RUB: "₽",
  SEK: "kr",
  SGD: "$",
  THB: "฿",
  TRY: "₺",
  UAH: "₴",
  USD: "$",
  VND: "₫",
  ZAR: "R",
};

export const name = [
  "Australian dollar",
  "Brazilian real 2",
  "Canadian dollar",
  "Czech koruna",
  "Danish krone",
  "Euro",
  "Hong Kong dollar",
  "Hungarian forint 1",
  "Indian rupee 3",
  "Israeli new shekel",
  "Japanese yen 1",
  "Malaysian ringgit 2",
  "Mexican peso",
  "New Taiwan dollar 1",
  "New Zealand dollar",
  "Norwegian krone",
  "Philippine peso",
  "Polish złoty",
  "Pound sterling",
  "Russian ruble",
  "Singapore dollar",
  "Swedish krona",
  "Swiss franc",
  "Thai baht",
  "United States dollar",
];
