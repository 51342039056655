import React, {useContext, useEffect, useState} from "react";

import {Icon} from "semantic-ui-react";
import {MessageContext} from "#providers";

interface Params {
}
const GlobalMessage: React.FC<Params> = (props) => {
  const [toastList, setMessageContext] = useContext(MessageContext)
  const [list, setList] = useState([]);
  const dismissTime = 3500;
  const position= "top-right"

  useEffect(()=>{setMessageContext(null)},[])

  useEffect(() => {
    if (toastList !== null) {
      const newList = [...list];
      newList.push({...toastList, id: list.length > 0? list[0].id +1 : 0})
      setList(newList);
    }

    return function cleanup(){
      setList([]);
    }
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length) {
        deleteToast(list[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };

  }, [toastList, dismissTime, list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    setList([...list]);
  };

  return (
      <>
        {list.length > 0 &&
            <div style={{position: 'fixed', top: '2%', zIndex: '999999999'}}
                 className={`notification-container ${position}`}>
              {list.map((toast, i) => (
                  <div
                      key={i}
                      className={`notification toast ${position}`}
                      style={{backgroundColor: toast.backgroundColor}}
                  >
                    <button onClick={() => deleteToast(toast.id)}><Icon name="close" alt=""/></button>
                    <div className="notification-image">
                      <Icon name={toast.icon} alt=""/>
                    </div>
                    <div>
                      <p className="notification-title">{toast.title}</p>
                      <p className="notification-message">{toast.description}</p>
                    </div>
                  </div>
              ))}
            </div>
        }
      </>
  );
};

export default GlobalMessage;


