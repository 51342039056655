import React, {useContext, useState} from "react";
import {Button, Checkbox, Form, Grid, Icon, Menu, Message, Modal,} from "semantic-ui-react";
import {MessagingService} from "#services";
import {MessageContext} from "#providers";
import {colors} from "#static/json";

interface Props {
  iconType: String;
}

const MobileNotification: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topic, setTopic] = useState("GENERAL");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [extra, setExtra] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [toastList, setMessageContext] = useContext(MessageContext);

  const options = [{ key: "GENERAL", text: "Geral", value: "GENERAL" }];

  const submitNotification = (e) => {
    e.preventDefault();
    if (accepted) {
      setLoading(true);
      const payload = {
        topic: topic,
        data: {
          title: title,
          body: body,
          ...(extra !== "" && { extra: extra }),
        },
      };
      MessagingService.sendBroadcastApp(payload)
        .then((r) => {
          setMessageContext({
            id: 1,
            title: "Notificação enviada!",
            description: "A notificação foi enviada a todos os usuários",
            backgroundColor: colors.success,
            icon: "check",
          });
          setLoading(false);
        })
        .catch((e) => {
          setMessageContext({
            id: 1,
            title: "Erro ao enviar",
            description:
              "Houve um erro ao enviar a notificação para todos os usuários",
            backgroundColor: colors.error,
            icon: "exclamation circle",
          });
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size={"large"}
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={
          <Menu.Item as="a" className={"menuText"}>
            {props.iconType == "EXPANDED" ? (
              <Grid>
                <Grid.Column
                  style={{ marginLeft: "1rem", marginRight: "1.5rem" }}
                >
                  <Icon
                    size={"large"}
                    name="bullhorn"
                    style={{ paddingTop: "3px" }}
                  />
                </Grid.Column>
                <Grid.Column style={{ marginTop: "0.4rem" }}>
                  <span className="menuVertical__item">Mobile</span>
                </Grid.Column>
              </Grid>
            ) : (
              <Icon.Group size={"large"}>
                <Icon name="bullhorn" />
              </Icon.Group>
            )}
          </Menu.Item>
        }
        open={open}
      >
        <Modal.Header>
          <span className="modal__header">
            Broadcast/Notificações Mata Nativa Móvel
          </span>
        </Modal.Header>
        <Modal.Content>
          <Form id="mobile-notification-form" onSubmit={submitNotification}>
            <Message
              color={"yellow"}
              icon="exclamation triangle"
              header="Envio de broadcast"
              content="Atenção! Notificações enviadas nessa categoria serão enviadas em broadcast para todos os usuários inscritos em um canal,
                            revise a mensagem atentamente antes de enviar"
              style={{
                marginBottom: "40px",
              }}
            />
            <Form.Field required>
              <label className="modal__label">
                Título da notificação (Max 65 Caracteres)
              </label>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={65}
                required
                placeholder="Título da notificação"
              />
            </Form.Field>
            <Form.Field required>
              <label className="modal__label">
                Corpo da notificação (Max 160 Caracteres)
              </label>
              <input
                value={body}
                onChange={(e) => setBody(e.target.value)}
                maxLength={160}
                required
                placeholder="Corpo da notificação"
              />
            </Form.Field>
            <Form.Field>
              <label className="modal__label">
                Link extra - Opcional (Ex: link de vídeo no youtube ou postagem
                do blog):
              </label>
              <input
                value={extra}
                onChange={(e) => setExtra(e.target.value)}
                placeholder="Link Extra"
              />
            </Form.Field>

            <Form.Field>
              <label className="modal__label">Canal de notificações:</label>
              <Form.Select
                options={options}
                defaultSelectedLabel={"Geral"}
                placeholder="Geral"
              />
            </Form.Field>
            <Form.Field required style={{ marginTop: "20px" }}>
              <Checkbox
                checked={accepted}
                onClick={() => setAccepted(!accepted)}
                className="modal__label"
                label="Confirmo que desejo enviar notificação para todos os dispositivos"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            className={"buttonCancel"}
            loading={loading}
            content={"Fechar"}
            onClick={() => setOpen(false)}
          />
          <Button
            loading={loading}
            type="submit"
            form="mobile-notification-form"
          >
            Enviar Broadcast
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default MobileNotification;
