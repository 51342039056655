import React, {createContext, useState} from "react";

export const MessageContext = createContext(null);

const {Provider} = MessageContext

const MessageProvider = ({children}) =>{
    const [state, setState] = useState(null)

    return <Provider value={[state, setState]}>{children}</Provider>
};

MessageProvider.context = MessageContext;

export default MessageProvider;
