import Service from "./service";

export default class ProductsService extends Service {

  private static get = async () => {
    let token = Service.token()
    try {
      const response = await fetch(`${Service.getAPIData()}/products`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      if(response.status === 200)
      {
        return Promise.resolve(response.json())
      }
      else if(response.status === 403){
        Service.redirectToProjects();
      }
      else{
        return Promise.reject(response.status);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static create = async (params) => {

    try {
      let payload = {products: [params.payload]};
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/products`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      });
      if(res.status === 201)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static update = async (params) => {

    try {
      let payload = {product: params.payload};
      let codproduto = params.codproduto
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/products/${codproduto}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };


  private static delete = async (params) => {

    try {
      let codproduto = params.codproduto
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/products/${codproduto}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.status);
    } catch (e) {
      return Promise.reject(e);
    }
  };



  static getProducts = async() =>{
    return await ProductsService.get();
  }

  static createProduct = async(params) =>{
    return await ProductsService.create({payload:params});
  }

  static updateProduct = async(params) =>{
    return await ProductsService.update({payload:params.payload, codproduto: params.codproduto});
  }

  static deleteProduct = async(params) =>{
    return await ProductsService.delete({codproduto: params.codproduto});
  }


}
