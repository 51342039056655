import React, {useEffect, useState} from "react";
import Service from "../../services/service";
import {AuthService} from "#services";
import {useNavigate} from "react-router";
import {PageLoader} from "#components";
import Cookie from "js-cookie";

const Auth: React.FC = (props) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        const token = Service.token();
        if (token) {
            authenticate();
        } else {
            console.log("FAILED_AUTH")
            navigate("/auth")
            setLoading(false)
        }
    }, []);

    const authenticate = async () => {
        try {
            await AuthService.get()
                .then((userData) => {
                    if(userData.content.administrador) {
                        setLoading(false)
                    }
                    else{
                        Cookie.remove("aTkn");
                        navigate("/auth")
                    }
                })
                .catch((e) => {
                    console.log("FAILED_AUTH",e)
                    Cookie.remove("aTkn");
                    navigate("/auth")
                });
        } catch (e) {
            console.log("FAILED_AUTH")
            console.error(e);
            setLoading(false)
        }
    };
  return (
        <PageLoader active={loading} />
  );
};

export default Auth;
