export const colors = {
  primary:"#144714",
  secondary:"#4F9B52",
  gray1:"#032103",
  gray2:"#768276",
  gray3:"#A8B2A8",
  gray4:"#EBF1EB",
  error:"#EB5757",
  warning:"#E2B93B",
  success:"#27AE60",
  auxiliary:"#7A6640",
}
