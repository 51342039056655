/*
    Author: Matheus Reis<matheus@cientec.net>
    Date: 25/02/2019
    Description: Definition of the i18n configuration
*/

import dayjs from "dayjs";
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import Backend from "i18next-http-backend"

import i18n from "i18next";

const {initReactI18next} = require("react-i18next");

const resources = require("#static/locales");

const detectionOptions = {
  // order and from where user language should be detected
  order: ["querystring", "cookie", "localStorage", "navigator"],
  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18nextLng",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  // domain to set the cookies
  caches: ["cookie", "localStorage"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieDomain: process.env.REACT_APP_DOMAIN,
};

let options = {
  load: "current",
  whitelist: resources.languages,
  fallbackLng: {
    "pt-BR": ["pt"],
    default: ["pt"],
  },
  ns: resources.lngNamespaces,
  detection: detectionOptions,
  defaultNS: "message",
  react: {
    wait: true,
  },
  interpolation: {
    format: function (value, format, lng) {
      if (format === "uppercase") return value.toUpperCase();
      if (value instanceof Date) {
        return dayjs(value).format(format);
      }
      return value;
    },
  },
};

// Test environment
switch (process.env.NODE_ENV) {
  case "test":
    options = Object.assign(options, {
      fallbackLng: "en",
      resources: resources.langs,
      ns: "translation",
      defaultNS: "translation",
    });
    break;
  case "production":
    options = Object.assign(options, {
      backend: {
        loadPath: "locales/{{lng}}/{{ns}}.json",
      },
    });
    break;
  case "development":
    options = Object.assign(options, {
      debug: true,
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });
    break;
}

i18n.use(initReactI18next).use(Backend).use(I18nextBrowserLanguageDetector).init(options);

i18n.on("languageChanged", function (lng) {
  // Keep language in sync
  if (i18n.services.languageDetector) {
    if (i18n.languages.includes(lng)) {
      i18n.services.languageDetector.cacheUserLanguage(lng);
    } else i18n.services.languageDetector.cacheUserLanguage("en");
  }
});

export { i18n };
