export default () => {
  const noop = () => {};
  const DEV_TOOLS = window["__REACT_DEVTOOLS_GLOBAL_HOOK__"];

  if (typeof DEV_TOOLS === "object" && process.env.NODE_ENV === "production") {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === "function" ? noop : null;
    }
  }
};
