import React, { useContext, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Form,
  Icon,
  Label,
  Menu,
  Message,
  Popup,
  Segment,
} from "semantic-ui-react";
import AG_GRID_LOCALE_PT from "#static/mockups/agTranslations";
import { NumberViewer } from "#components/gridComponents";
import { useSelector } from "react-redux";
import { LicencesService } from "#services";
import { MessageContext } from "#providers";
import { colors } from "#static/json";
import { DirectLicence } from "#components/licences";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSquareCheck, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface params {
  user: any;
  refresh?: any;
}

const UserLicences: React.FC<params> = (props) => {
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [toastList, setMessageContext] = useContext(MessageContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState({
    codigolicenca: "Código licença",
    periododias: "Período (dias)",
    statusatual: "Status",
    createdat: "Criada em",
    activatedat: "Ativada em",
    expiresat: "Expira em",
    paidat: "Pago em",
    emailproprietario: "Email do propritário",
    emailusuariousando: "Email do usuário usando",
    suspendedat: "Suspensa em",
    diasrestantesaosuspender: "Dias restantes (Após congelamento)",
    observation: "Observação",
    codlicenca: "ID Licença",
  });

  const columnsDefinitions = Object.keys(columns).map((column) => {
    return {
      field: column,
      headerName: columns[column],
    };
  });

  const [columnsChecked, setColumnsChecked] = useState({});
  const [columnDefs, setColumnDefs] = useState(columnsDefinitions);

  // Change status cell with custom tag color
  const addCustomStatusLabel = (params: any) => {
    if (
      params.value == undefined ||
      params.value == "" ||
      params.value == null
    ) {
      return params.value;
    }
    const colorsInfo = {
      EXPIRED: "red",
      ACTIVATED: "green",
      SUSPENDED: "blue",
      BLOCKED: "black",
      NOT_YET_ACTIVATED: "yellow",
    };
    const colorsNames = {
      EXPIRED: "EXPIRADA",
      ACTIVATED: "ATIVADA",
      SUSPENDED: "SUSPENSA",
      BLOCKED: "BLOQUEADA",
      NOT_YET_ACTIVATED: "NÃO ATIVADA",
    };
    const getStatusColor = (type: any): any =>
      colorsInfo[type] != undefined && colorsInfo[type] != ""
        ? colorsInfo[type]
        : "yellow";
    const getStatusName = (type: any): any =>
      colorsNames[type] != undefined && colorsNames[type] != ""
        ? colorsNames[type]
        : "NÃO ATIVADA";

    return (
      <span>
        <Label color={getStatusColor(params.value)} horizontal>
          {getStatusName(params.value)}
        </Label>
      </span>
    );
  };

  columnDefs[2]["cellRenderer"] = addCustomStatusLabel;

  useEffect(() => {
    if (props && props.user) {
      refreshLicences();
    }
  }, []);

  const refreshLicences = () => {
    setLoading(true);
    LicencesService.getLicencesByEmail({
      userEmail: props.user.email,
    })
      .then((res) => {
        setRowData(res.content.licences);
        setLoading(false);
        props.refresh();
      })
      .catch((err) => {
        setMessageContext({
          id: 1,
          title: "Erro",
          description:
            "Houve um erro listar as licenças. Por gentileza chame um dev.",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
        setLoading(false);
      });
  };

  // grid funcions
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  /**
   * filter the column according to the selected checkbox
   * @param key key id
   */
  const columnFilter = (key: string) => {
    if (columnsChecked[key] === true) {
      gridColumnApi.setColumnVisible(key, false);
    }
    if (columnsChecked[key] === false) {
      gridColumnApi.setColumnVisible(key, true);
    }
    setColumnsChecked({ ...columnsChecked, [key]: !columnsChecked[key] });
  };

  /**
   * checks or unchecks all columns
   * @param allChecked true/false
   */
  const checkAllColumns = (allChecked) => {
    let tempColumns = {};
    if (allChecked) {
      Object.keys(columnsChecked).forEach(function (key) {
        tempColumns[key] = true;
        gridColumnApi.setColumnVisible(key, true);
      });
    } else {
      Object.keys(columnsChecked).forEach(function (key) {
        tempColumns[key] = false;
        gridColumnApi.setColumnVisible(key, false);
      });
    }
    setColumnsChecked(tempColumns);
  };

  const resizeColumns = () => {
    if (!!gridColumnApi) {
      const allColumnIds = [];
      gridColumnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });

      gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };

  const reverseLicenceTransaction = () => {
    setLoading(true);
    let selectedRow = gridApi.getSelectedRows()[0];
    const codlicenca = selectedRow["codlicenca"];

    LicencesService.reverseTransactionByLicence({ licenceId: codlicenca })
      .then((res) => {
        setMessageContext({
          id: 1,
          title: "Sucesso",
          description: "Pagamento estornado com sucesso e licença excluída.",
          backgroundColor: colors.success,
          icon: "exclamation circle",
        });
        refreshLicences();
        setLoading(false);
        setOpenConfirm(false);
        props.refresh();
      })
      .catch((err) => {
        setMessageContext({
          id: 1,
          title: "Erro",
          description:
            "Houve um erro ao estornar o pagamento. Por gentileza chame um dev.",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
        setLoading(false);
      });
  };

  const freezeLicence = (licenceId: string) => {
    setLoading(true);
    LicencesService.freezeLicence({ licenceId })
      .then((res) => {
        setMessageContext({
          id: 1,
          title: "Sucesso",
          description: "Licença congelada com sucesso.",
          backgroundColor: colors.success,
          icon: "exclamation circle",
        });
        setLoading(false);
        refreshLicences();
      })
      .catch((err) => {
        setLoading(false);
        setMessageContext({
          id: 1,
          title: "Erro",
          description:
            "Houve um erro ao congelar a licença. Por gentileza chame um dev.",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
      });
  };

  const activateFreezedLicence = (licenceId: string) => {
    setLoading(true);
    LicencesService.reactivateFreezedLicence({ licenceId })
      .then((res) => {
        setMessageContext({
          id: 1,
          title: "Sucesso",
          description: "Licença reativada com sucesso",
          backgroundColor: colors.success,
          icon: "exclamation circle",
        });
        refreshLicences();
        setLoading(false);
      })
      .catch((err) => {
        setMessageContext({
          id: 1,
          title: "Erro",
          description:
            "Houve um erro ao reativar a licença. Por gentileza chame um dev.",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
        setLoading(false);
      });
  };

  const suspendLicenceAction = () => {
    let selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 1) {
      setMessageContext({
        id: 1,
        title: "Erro",
        description: "É necessário selecionar apenas uma licença.",
        backgroundColor: colors.warning,
        icon: "exclamation circle",
      });
    }
    let selectedRow = selectedRows[0];

    if (!!selectedRow) {
      if (selectedRow["status"] == "SUSPENDED") {
        setMessageContext({
          id: 1,
          title: "Atenção",
          description: "Licença selecionada já está suspensa.",
          backgroundColor: colors.warning,
          icon: "exclamation circle",
        });
        return;
      }

      if (selectedRow["codlicenca"] != "") {
        freezeLicence(selectedRow.codlicenca);
      }
    } else {
      setMessageContext({
        id: 1,
        title: "Erro",
        description: "É necessário selecionar uma licença para suspender.",
        backgroundColor: colors.warning,
        icon: "exclamation circle",
      });
    }
  };

  const activateSuspendedLicenceAction = () => {
    let selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 1) {
      setMessageContext({
        id: 1,
        title: "Erro",
        description: "É necessário selecionar apenas uma licença.",
        backgroundColor: colors.warning,
        icon: "exclamation circle",
      });
    }
    let selectedRow = selectedRows[0];

    if (!!selectedRow) {
      if (selectedRow["status"] != "SUSPENDED") {
        setMessageContext({
          id: 1,
          title: "Atenção",
          description: "Licença selecionada não está suspensa.",
          backgroundColor: colors.warning,
          icon: "exclamation circle",
        });
        return;
      }

      if (selectedRow["codlicenca"] != "") {
        activateFreezedLicence(selectedRow.codlicenca);
      }
    } else {
      setMessageContext({
        id: 1,
        title: "Erro",
        description: "É necessário selecionar uma licença para reativar.",
        backgroundColor: colors.warning,
        icon: "exclamation circle",
      });
    }
  };

  const handleOpenConfirmToEstornar = () => {
    let selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 1) {
      setMessageContext({
        id: 1,
        title: "Erro",
        description:
          "É necessário selecionar apenas uma licença para estornar.",
        backgroundColor: colors.warning,
        icon: "exclamation circle",
      });
    }
    let selectedRow = selectedRows[0];

    if (!!selectedRow) {
      setOpenConfirm(true);
    } else {
      setMessageContext({
        id: 1,
        title: "Erro",
        description: "É necessário selecionar uma licença para estornar.",
        backgroundColor: colors.warning,
        icon: "exclamation circle",
      });
    }
  };

  return (
    <>
      <Confirm
        open={openConfirm}
        confirmButton={
          <Button
            content={"Estornar"}
            loading={loading}
            className={"buttonDelete"}
            onClick={() => reverseLicenceTransaction()}
          />
        }
        cancelButton={
          <Button
            content={"Fechar"}
            loading={loading}
            className={"buttonCancel"}
            onClick={() => setOpenConfirm(false)}
          />
        }
        content={
          <Message error style={{ margin: "1rem", fontSize: "1.2rem" }}>
            <b>Atenção!</b> <br /> Tem certeza que deseja estornar o pagamento
            desta licença?{" "}
            <b>
              Ao estornar todas as licenças ligadas ao pagamento da licença
              selecionada serão excluídas da base.
            </b>
          </Message>
        }
      />

      <span className="text__form__title">
        Lista de Licenças &nbsp;
        <Popup
          trigger={
            <Icon
              name="question circle outline"
              style={{ fontSize: "1em", marginBottom: "4px" }}
            />
          }
          content={
            "Licenças onde esse usuário é proprietário, não quer dizer que esteja usando a licença."
          }
        ></Popup>
      </span>
      <div
        style={{
          paddingTop: "5px",
        }}
      >
        <span className="text__secondary">
          {`Total: ${rowData ? rowData.length : 0}`}
        </span>
      </div>

      <Menu borderless text>
        <Menu.Menu position={"left"}>
          <Popup
            className={"popupBackground"}
            position={"bottom left"}
            content={
              <>
                <Form
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    maxHeight: "60vh",
                  }}
                >
                  <Button
                    className={"buttonBorderTransparentFluid"}
                    fluid
                    onClick={() => resizeColumns()}
                    content={"Ajustar Colunas"}
                    icon={"resize horizontal"}
                  />
                  <Form.Group>
                    <Button
                      content={"Todos"}
                      className={"buttonBorderTransparent"}
                      onClick={() => checkAllColumns(true)}
                    >
                      <span style={{ display: "inline-block" }}>
                        <FontAwesomeIcon
                          icon={faSquareCheck as IconProp}
                          className={"iconLeft"}
                        />
                        Todos
                      </span>
                    </Button>
                    <Button
                      content={"Nenhum"}
                      className={"buttonBorderTransparent"}
                      onClick={() => checkAllColumns(false)}
                    >
                      <FontAwesomeIcon
                        icon={faSquare as IconProp}
                        className={"iconLeft"}
                      />{" "}
                      Nenhum
                    </Button>
                  </Form.Group>
                  {Object.keys(columns).map((key) => {
                    return (
                      <Form.Field key={key}>
                        <Checkbox
                          onChange={() => columnFilter(key)}
                          checked={columnsChecked[key]}
                          label={columns[key]}
                        />
                      </Form.Field>
                    );
                  })}
                </Form>
              </>
            }
            on="click"
            trigger={
              <Menu.Item as="a" name="filtrar">
                <Button
                  className={"button--secondary"}
                  content="Colunas"
                  icon={"filter"}
                />
              </Menu.Item>
            }
          />
        </Menu.Menu>

        <Menu.Menu position={"right"}>
          <DirectLicence user={props.user} refreshLicences={refreshLicences} />
          <Button
            icon="times"
            className="buttonDelete__secondary"
            content="Estornar Licença"
            style={{ marginRight: "20px" }}
            loading={loading}
            onClick={() => handleOpenConfirmToEstornar()}
          />

          <Popup
            trigger={
              <Button
                icon="lock"
                className="buttonInfo__secondary"
                content="Suspender Licença"
                onClick={suspendLicenceAction}
                loading={loading}
              />
            }
            content={"Congela a licença selecionada no dia e hora atual."}
          ></Popup>
          <Popup
            trigger={
              <Button
                icon="unlock"
                className="buttonInfo__secondary"
                content="Reativar Licença"
                loading={loading}
                onClick={activateSuspendedLicenceAction}
              />
            }
            content={"Reativa uma licença suspensa."}
          ></Popup>
        </Menu.Menu>
      </Menu>

      <div
        style={{
          overflow: "auto",
          maxHeight: "42vh",
          marginBottom: "50px",
          marginTop: "10px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
      >
        <Container
          fluid
          style={{ width: "100%", overflow: "auto", height: "32vh" }}
        >
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className={
              theme.darkMode ? "ag-theme-balham-dark" : "ag-theme-balham"
            }
          >
            <AgGridReact
              localeText={AG_GRID_LOCALE_PT}
              suppressDragLeaveHidesColumns={true}
              rowData={rowData}
              defaultColDef={{
                editable: false,
                resizable: true,
                sortable: true,
              }}
              rowSelection={"single"}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              enableCellTextSelection
            >
              <AgGridColumn
                cellRendererFramework={NumberViewer}
                field={"codigolicenca"}
                headerName={"Código Licença"}
              />

              <AgGridColumn
                cellRendererFramework={NumberViewer}
                field={"periododias"}
                headerName={"Período dias"}
              />

              <AgGridColumn
                cellRendererFramework={NumberViewer}
                field={"status"}
                headerName={"Status"}
              />

              <AgGridColumn
                cellRendererFramework={NumberViewer}
                field={"createdat"}
                headerName={"Criada em"}
              />

              <AgGridColumn
                cellRendererFramework={NumberViewer}
                field={"activatedat"}
                headerName={"Ativada em"}
              />
            </AgGridReact>
          </div>
        </Container>
      </div>
    </>
  );
};

export default UserLicences;
