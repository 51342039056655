import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState,} from "react";
import {useTranslation} from "react-i18next";
import MaskedInput from "react-text-mask"
import {Form} from "semantic-ui-react";
import {MessageContext} from "#providers";
import {AuxiliaryFunctions} from "#static/scripts";

interface InsertTreeParams {
  refresh: any;
  open: boolean;
  changeOpen: any;
  ref: any;
  changeLoading: any;
    submitVoucher: any;
}
const AddCoupon: React.FC<InsertTreeParams> = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [messageContext, setMessageContext] = useContext(MessageContext);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

    const defaultState = {
        voucherCode: "CUPOM",
        voucherType: "DESCONTO_NO_VALOR",
        identificationType: "PREFIX",
        expirationDate: "",
        description: "",
        targetUserCode: "",
        targetLicenceCode: "",
        licenceDays: "",
        numberOfGeneralUses: 1,
        numberOfUsesPerUser: 1,
        percentualDiscount: "",
        integerDiscount: "",
        minimumPurchaseValue: "",
        minimumLicencesPerPurchase: "",
        maximumLicencesPerPurchase: "",
        licencesNumber: 1,
        totalValue: "",
    }

  useEffect(() => {
    props.changeLoading(loading);
  }, [loading]);

  const [voucherType, setVoucherType] = useState([
      {key: "PREFIX", text: "Prefixo + Aleatório (ex:'MATANATIVA5D13')", value: "PREFIX"},
      {key: "RANDOM", text: "Aleatório (ex:'5D13...')", value: "RANDOM"},
      {key: "MANUAL", text: "Código inteiro manual", value: "MANUAL"}
      ]
  )

  const [values, setValues] = useState(defaultState);
  const [enabledField, setEnabledFields] = useState({
      useMinimumPurchaseValue: false,
      discountType: "PERCENT",
      useTargetUser: false,
      useMinimumLicencesPerPurchase: false,
      useMaximumLicencesPerPurchase: false,
      useDescription: false,
  });

  const handleFormChange = (field, data) => {
    if (data.checked !== undefined)
      setValues({ ...values, [field]: data.checked });
    else setValues({ ...values, [field]: data.value });
  };

  const handleEnableFieldsChange = (field, data) => {
    if (data.checked !== undefined)
      setEnabledFields({ ...enabledField, [field]: data.checked });
  };

    const handleRadioChange = (field, data) => {
        setEnabledFields({ ...enabledField, [field]: data });
    };

  useImperativeHandle(ref, () => ({
      submit() {
          // @ts-ignore
          formRef.current.click()
      }
  }));

    const createDiscountCoupon = (e) =>{
        e.preventDefault();
        let submitValues = {
            "type": "DESCONTO_NO_VALOR",
            ...(values.identificationType === 'PREFIX') && {codeCouponPrefix: values.voucherCode.toUpperCase()} ,
            ...(values.identificationType === 'MANUAL') && {codeCoupon: values.voucherCode.toUpperCase()} ,
            ...(enabledField.discountType === 'PERCENT') && {discountPercentual: parseFloat(values.percentualDiscount.toString().replace(',','.'))} ,
            ...(enabledField.discountType === 'FIXED') && {discountInteger: parseFloat(values.integerDiscount.toString().replace(',','.'))} ,
            ...(enabledField.useMinimumPurchaseValue) && {minimumTotal: values.minimumPurchaseValue} ,
            ...(enabledField.useMinimumLicencesPerPurchase) && {minimumLicences: values.minimumLicencesPerPurchase} ,
            maximumLicences: enabledField.useMaximumLicencesPerPurchase ? values.maximumLicencesPerPurchase : 999 ,
            "expiresAt": AuxiliaryFunctions.convertDate(values.expirationDate),
            "individualUseLimit": values.numberOfUsesPerUser,
            "generalUseLimit": values.numberOfGeneralUses,
            ...(enabledField.useTargetUser) && {userEmail: values.targetUserCode} ,
            ...(enabledField.useDescription) && {description: values.description} ,
        }
        props.submitVoucher(submitValues);
        setValues(defaultState);
    }

  return (
    <>
      <Form onSubmit={createDiscountCoupon} className={"voucherForm"} >
          <Form.Group>
              <Form.Input
                  width={3}
                  label={"Identificador"}
                  value={values.voucherCode}
                  disabled={values.identificationType === "RANDOM"}
                  required={values.identificationType !== "RANDOM"}
                  onChange={(e, value) => handleFormChange("voucherCode", value)}
              />
              <Form.Select
                  width={8}
                  value={values.identificationType}
                  onChange={(e, value) =>
                      handleFormChange("identificationType", value)
                  }
                  label={"Identificador do voucher"}
                  options={voucherType}
              />
              <Form.Input
                  width={5}
                  label={
                  <>
                      <Form.Group style={{marginBottom: '3px'}}>
                          <Form.Radio
                              label="Desc Percentual"
                              value="PERCENT"
                              onClick={() => handleRadioChange("discountType", "PERCENT")}
                              checked={enabledField.discountType === "PERCENT"}
                          />
                          <Form.Radio
                              label="Desc Fixo"
                              value="FIXED"
                              required
                              onClick={() => handleRadioChange("discountType", "FIXED")}
                              checked={enabledField.discountType === "FIXED"}
                          />
                      </Form.Group>
                  </>
                  }
                  value={enabledField.discountType === "PERCENT" ? values.percentualDiscount : values.integerDiscount}
                  required
                  onChange={
                  (e, value) =>
                      handleFormChange(enabledField.discountType === "PERCENT" ? "percentualDiscount" : "integerDiscount", value)}
              />

          </Form.Group>

          <Form.Group widths={"equal"}>
              <Form.Input
                  label={
                      <Form.Checkbox
                          disabled={false}
                          checked={enabledField.useMinimumPurchaseValue}
                          required={enabledField.useMinimumPurchaseValue}
                          onChange={(e, value) =>
                              handleEnableFieldsChange("useMinimumPurchaseValue", value)
                          }
                          label={"Valor mínimo de compra"}
                      />
                  }
                  required={enabledField.useMinimumPurchaseValue}
                  value={values.minimumPurchaseValue}
                  type={"number disabled"}
                  readOnly={!enabledField.useMinimumPurchaseValue}
                  onChange={(e, value) => handleFormChange("minimumPurchaseValue", value)}
              />

              <Form.Input
                  label={
                      <Form.Checkbox
                          disabled={false}
                          checked={enabledField.useMinimumLicencesPerPurchase}
                          required={enabledField.useMinimumLicencesPerPurchase}
                          onChange={(e, value) =>
                              handleEnableFieldsChange("useMinimumLicencesPerPurchase", value)
                          }
                          label={"Mínimo de licenças"}
                      />
                  }
                  required={enabledField.useMinimumLicencesPerPurchase}
                  value={values.minimumLicencesPerPurchase}
                  type={"number disabled"}
                  readOnly={!enabledField.useMinimumLicencesPerPurchase}
                  onChange={(e, value) => handleFormChange("minimumLicencesPerPurchase", value)}
              />

              <Form.Input
                  label={
                      <Form.Checkbox
                          disabled={false}
                          checked={enabledField.useMaximumLicencesPerPurchase}
                          required={enabledField.useMaximumLicencesPerPurchase}
                          onChange={(e, value) =>
                              handleEnableFieldsChange("useMaximumLicencesPerPurchase", value)
                          }
                          label={"Máximo de licenças"}
                      />
                  }
                  required={enabledField.useMaximumLicencesPerPurchase}
                  value={values.maximumLicencesPerPurchase}
                  type={"number disabled"}
                  readOnly={!enabledField.useMaximumLicencesPerPurchase}
                  onChange={(e, value) => handleFormChange("maximumLicencesPerPurchase", value)}
              />

          </Form.Group>

          <Form.Group widths={"equal"}>
              <Form.Input
                  label={"Data Expiração do Voucher"}
                  required
                  input={
                      <MaskedInput
                          required
                          mask={[
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              "/",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                          ]}
                          placeholder={"DD/MM/YYYY"}
                          name="birthdate"
                          value={values.expirationDate}
                          autoComplete="none"
                          onChange={(e) => setValues({...values,expirationDate: e.target.value})}
                      />
                  }
              />

              <Form.Input
                  label={"Numero de usos gerais"}
                  value={values.numberOfGeneralUses}
                  required
                  onChange={(e, value) => handleFormChange("numberOfGeneralUses", value)}
              />

              <Form.Input
                  label={"Usos por usuário"}
                  value={values.numberOfUsesPerUser}
                  required
                  onChange={(e, value) => handleFormChange("numberOfUsesPerUser", value)}
              />

          </Form.Group>
          <Form.Group>
              <Form.Input
                  label={
                      <Form.Checkbox
                          disabled={false}
                          checked={enabledField.useTargetUser}
                          required={enabledField.useTargetUser}
                          onChange={(e, value) =>
                              handleEnableFieldsChange("useTargetUser", value)
                          }
                          label={"Identificador do usuário destino"}
                      />
                  }
                  required={enabledField.useTargetUser}
                  value={values.targetUserCode}
                  type={"number disabled"}
                  readOnly={!enabledField.useTargetUser}
                  onChange={(e, value) => handleFormChange("targetUserCode", value)}
              />
          </Form.Group>
          <Form.TextArea
              label={
                  <Form.Checkbox
                      disabled={false}
                      checked={enabledField.useDescription}
                      required={enabledField.useDescription}
                      onChange={(e, value) =>
                          handleEnableFieldsChange("useDescription", value)
                      }
                      label={"Descrição"}
                  />
              }
              required={enabledField.useDescription}
              value={values.description}
              readOnly={!enabledField.useDescription}
              onChange={(e, value) => handleFormChange("description", value)}
          />
          <button hidden type={"submit"} ref={formRef}/>
      </Form>
    </>
  );
});

export default AddCoupon;
