import Service from "./service";

export default class ClientesService extends Service {
  static MAX_NUMBER_BY_PAGE = 100;
  static get = async (params) => {
    try {
      const page = params.page;
      const token = Service.token();
      const limitAndPageString = `limit=${ClientesService.MAX_NUMBER_BY_PAGE}&page=${page}`;

      let searchString = "";
      if (params.payload.hasOwnProperty("searchKey"))
        searchString = `&search=[${params.payload.searchKey}]`;

      let orderAndOrderByString = "";
      if (params.order && params.orderBy)
        orderAndOrderByString = `&order=${params.order}&orderBy=${params.orderBy}`;

      const res = await fetch(
        `${Service.getAPIData()}/admin/users?${limitAndPageString}${searchString}${orderAndOrderByString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getUser = async (params: any) => {
    const codusuario = params.codusuario;
    const token = Service.token();
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/users/${codusuario}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static update = async (projectData) => {
    try {
      return new Promise(async (resolve, reject) => {
        let payload = { user: projectData.payload };
        const response = await fetch(`${Service.getAPIData()}/users/me`, {
          method: "PATCH",
          headers: Service.getHeadersPOST(),
          body: JSON.stringify(payload),
        });
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getFollowUPs = async (params) => {
    let userId = params.userId;
    try {
      const token = Service.token();
      const res = await fetch(
        `${Service.getAPIData()}/admin/users/${userId}/atendimentos`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static updateFollowUP = async (atendimentoData: any) => {
    let { userId, atendimentoId, payload } = atendimentoData;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/users/${userId}/atendimentos/${atendimentoId}`,
          {
            method: "PATCH",
            headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static createFollowUP = async (atendimentoData: any) => {
    let { userId, payload } = atendimentoData;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/users/${userId}/atendimentos`,
          {
            method: "POST",
            headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 201) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static deleteFollowUP = async (atendimentoData) => {
    let { userId, atendimentoId } = atendimentoData;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/users/${userId}/atendimentos/${atendimentoId}`,
          {
            method: "DELETE",
            headers: Service.getHeadersPOST(),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateUser = async (userData) => {
    return await ClientesService.update(userData);
  };

  static readUser = async (userData) => {
    return await ClientesService.getUser(userData);
  };

  static getClientes = async (params) => {
    return await ClientesService.get(params);
  };

  static getAtendimentos = async (params) => {
    return await ClientesService.getFollowUPs(params);
  };

  static insertAtendimento = async (params) => {
    return await ClientesService.createFollowUP(params);
  };

  static updateAtendimento = async (params) => {
    return await ClientesService.updateFollowUP(params);
  };

  static deleteAtendimento = async (params) => {
    return await ClientesService.deleteFollowUP(params);
  };
}
