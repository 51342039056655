import Service from "./service";

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 5000 }: any = options; // Default is 5 seconds

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

export default class StatusService extends Service {

  static getApiDiskUsage = async (): Promise<any> => {
    try {
      const token = Service.token()
      const response = await fetchWithTimeout(`${Service.getAPIData()}/system/disk`, {
        method: "GET",
        timeout: 5000,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if( response.status === 200) {
        return Promise.resolve(response.json());
      } else {
        return "FAILED_TO_FETCH";
      }
    } catch (error) {
      return "FAILED_TO_FETCH";
    }
  };

  static getWordPressStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout("https://matanativa.com.br", {
        method: "GET",
        timeout: 5000,
        mode: "no-cors",
      });
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  static getAdminStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout(
        "https://admin.matanativa.com.br",
        {
          method: "GET",
          timeout: 5000,
          mode: "no-cors",
        }
      );
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  static getLoginStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout(
        "https://login.matanativa.com.br",
        {
          method: "GET",
          timeout: 5000,
          mode: "no-cors",
        }
      );
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  static getWebStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout("https://web.matanativa.com.br", {
        method: "GET",
        timeout: 5000,
        mode: "no-cors",
      });
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  static getApiStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout("https://api.matanativa.com.br", {
        method: "GET",
        timeout: 5000,
        mode: "no-cors",
      });
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  static getApiDataStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout("https://api.data.matanativa.com.br", {
        method: "GET",
        timeout: 5000,
        mode: "no-cors",
      });
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  static getApiCronStatus = async (): Promise<any> => {
    try {
      const response = await fetchWithTimeout("https://api.cron.matanativa.com.br", {
        method: "GET",
        timeout: 5000,
        mode: "no-cors",
      });
      return response;
    } catch (error) {
      console.log(error.name === "AbortError");
      return "FAILED_TO_FETCH";
    }
  };

  private static getSsl = async (): Promise<any> => {
    try {
      const token = Service.token();

      const res = await fetch(`${Service.getAPIData()}/security/ssl`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getStatusFromSsl = async() =>{
    return await StatusService.getSsl();
  }
}
