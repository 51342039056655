import Cookie from "js-cookie";

export default class Service {
  static getAPI = () => process.env.REACT_APP_API_CALC_URL+'/'+process.env.REACT_APP_API_VERSION;
  static getAPIData = () => process.env.REACT_APP_API_URL+'/'+process.env.REACT_APP_API_VERSION;
  static getAPICrons = () => process.env.REACT_APP_API_CRONS_URL+'/'+process.env.REACT_APP_API_VERSION;
  static token = () => process.env.REACT_APP_PROJECT_TYPE !== 'stage' ? Cookie.get("aTkn") : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDb2RVc3VhcmlvIjoiY2E3MGI1ZTktZGRkZi00ODU1LWIzZTctMWQwMTk1NTA2YWI3IiwicGVybWlzc2lvbnMiOnsiYWRtaW4iOnRydWV9LCJpYXQiOjE2Njk3MjU3NzJ9.UadOmsPHgG46kalTIpWa46K-4j18XlHkciKd8aQvUvc";
  static redirectToProjects = ()=>{//
    window.location.href = '/auth'
  }
  static deviceId = () => Cookie.get("deviceId")
  static getHeadersPOST = () =>(
    { // POST and PATCH headers
      Authorization: `Bearer ${Service.token()}`,
      "Content-Type": "application/json",
      deviceid: Service.deviceId()
    }
  )
}

export class Response {
  public response: object;
}
