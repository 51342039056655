import { Divider, Icon, Menu } from "semantic-ui-react";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GlobalContext } from "#providers/globalContext";
import { MobileNotification, WebNotifications } from "#components/firebase";

interface VerticalSidebarOpenProjectCollapsed {
  sidebarVisible: boolean;
  toggleSidebar: any;
}

let VerticalSidebarOpenProjectCollapsed: React.FC<
  VerticalSidebarOpenProjectCollapsed
> = (props) => {
  const navigate = useNavigate();
  const [globalContext, dispatch] = useContext(GlobalContext);

  const openDashboardStatus = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "STATUS" } });
    navigate("/");
  };

  const openUsers = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "USERS" } });
    navigate("/clientes");
  };

  const openVouchers = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "VOUCHERS" } });
    navigate("/vouchers");
  };

  const openLicences = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "LICENCES" } });
    navigate("/licences");
  };

  const openTransactions = () => {
    dispatch({
      type: "setSelectedSidebar",
      payload: { sidebar: "TRANSACTIONS" },
    });
    navigate("/transactions");
  };

  const openProducts = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "PRODUCTS" } });
    navigate("/products");
  };

  const openBuscas = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "SEARCH" } });
    navigate("/buscas");
  };

  const openEmpresas = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "COMPANIES" } });
    navigate("/companies");
  };

  const openCalculos = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "CALCULOS" } });
    navigate("/calculos");
  };

  const openRotinas = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "ROTINAS" } });
    navigate("/rotinas");
  };

  return (
    <Menu
      vertical
      secondary
      icon
      style={{ position: "fixed", width: "6rem", marginTop: "0" }}
    >
      <Menu.Item onClick={props.toggleSidebar} as="a">
        <FontAwesomeIcon
          icon={faChevronCircleRight as IconProp}
          className={"graySidebarIconColapsed"}
          size="lg"
        />
      </Menu.Item>

      <Divider className="menuVertical__divider__top" />

      <Menu.Item
        onClick={openDashboardStatus}
        as="a"
        active={globalContext.sidebar === "STATUS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="tasks" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openUsers}
        as="a"
        active={globalContext.sidebar === "USERS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="users" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openLicences}
        as="a"
        active={globalContext.sidebar === "LICENCES"}
      >
        <Icon.Group size={"large"}>
          <Icon name="id card outline" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openTransactions}
        as="a"
        active={globalContext.sidebar === "TRANSACTIONS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="money bill alternate outline" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openVouchers}
        as="a"
        active={globalContext.sidebar === "VOUCHERS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="ticket" />
        </Icon.Group>
      </Menu.Item>

      <Divider className="menuVertical__divider" />

      <Menu.Item
        onClick={openProducts}
        as="a"
        active={globalContext.sidebar === "PRODUCTS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="ordered list" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openBuscas}
        as="a"
        active={globalContext.sidebar === "SEARCH"}
      >
        <Icon.Group size={"large"}>
          <Icon name="search" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openEmpresas}
        as="a"
        active={globalContext.sidebar === "COMPANIES"}
      >
        <Icon.Group size={"large"}>
          <Icon name="building" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openCalculos}
        as="a"
        active={globalContext.sidebar === "CALCULOS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="superscript" />
        </Icon.Group>
      </Menu.Item>

      <Menu.Item
        onClick={openRotinas}
        as="a"
        active={globalContext.sidebar === "ROTINAS"}
      >
        <Icon.Group size={"large"}>
          <Icon name="clock" />
        </Icon.Group>
      </Menu.Item>

      <Divider className="menuVertical__divider" />

      <MobileNotification iconType="COLAPSED" />
      <WebNotifications iconType="COLAPSED" />
    </Menu>
  );
};

export default VerticalSidebarOpenProjectCollapsed;
