import { Divider, Grid, Icon, Menu, Popup, Sidebar } from "semantic-ui-react";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GlobalContext } from "#providers/globalContext";
import { MobileNotification, WebNotifications } from "#components/firebase";

interface VerticalSidebarOpenProject {
  sidebarVisible: boolean;
  toggleSidebar: any;
}

let VerticalSidebarOpenProject: React.FC<VerticalSidebarOpenProject> = (
  props
) => {
  const navigate = useNavigate();
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));
  const [globalContext, dispatch] = useContext(GlobalContext);

  const openDashboardStatus = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "STATUS" } });
    navigate("/");
  };

  const openUsers = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "USERS" } });
    navigate("/clientes");
  };

  const openVouchers = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "VOUCHERS" } });
    navigate("/vouchers");
  };

  const openLicences = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "LICENCES" } });
    navigate("/licences");
  };

  const openTransactions = () => {
    dispatch({
      type: "setSelectedSidebar",
      payload: { sidebar: "TRANSACTIONS" },
    });
    navigate("/transactions");
  };

  const openProducts = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "PRODUCTS" } });
    navigate("/products");
  };

  const openBuscas = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "SEARCH" } });
    navigate("/buscas");
  };

  const openEmpresas = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "COMPANIES" } });
    navigate("/companies");
  };

  const openCalculos = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "CALCULOS" } });
    navigate("/calculos");
  };

  const openRotinas = () => {
    dispatch({ type: "setSelectedSidebar", payload: { sidebar: "ROTINAS" } });
    navigate("/rotinas");
  };

  return (
    <Sidebar
      as={Menu}
      secondary
      vertical
      animation={"slide along"}
      visible={props.sidebarVisible}
      direction={"left"}
      className={"sidebarMenu sidebarBackground"}
    >
      <Menu.Item className={"projectInfoSidebar noHover"}>
        <Menu.Menu>
          <Popup
            className={"popupBackground topbarMenu"}
            position={"right center"}
            content={"Minimizar menu lateral."}
            trigger={
              <FontAwesomeIcon
                onClick={props.toggleSidebar}
                icon={faChevronCircleLeft as IconProp}
                className={"graySidebarIcon"}
                size="lg"
              />
            }
          />
        </Menu.Menu>
      </Menu.Item>

      <Divider className="menuVertical__divider__top" />

      <Menu.Item style={{ paddingLeft: "26px", paddingBottom: "14px" }}>
        <span className="menuVertical__header">Visão Geral</span>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openDashboardStatus}
        as="a"
        active={globalContext.sidebar === "STATUS"}
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon
              size={"large"}
              name="tasks"
              style={{ paddingTop: "3px" }}
              className="menuVertical__item__icon"
            />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Status</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openUsers}
        as="a"
        active={globalContext.sidebar === "USERS"}
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon size={"large"} name="users" style={{ paddingTop: "3px" }} />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Clientes</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openLicences}
        active={globalContext.sidebar === "LICENCES"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon
              size={"large"}
              name="id card outline"
              style={{ paddingTop: "3px" }}
            />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Licenças</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openTransactions}
        active={globalContext.sidebar === "TRANSACTIONS"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon
              size={"large"}
              name="money bill alternate outline"
              style={{ paddingTop: "3px" }}
            />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Transações</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openVouchers}
        active={globalContext.sidebar === "VOUCHERS"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon size={"large"} name="ticket" style={{ paddingTop: "3px" }} />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Vouchers</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Divider className="menuVertical__divider" />

      <Menu.Item style={{ paddingLeft: "26px", paddingBottom: "14px" }}>
        <span className="menuVertical__header">Área do Admin</span>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openProducts}
        active={globalContext.sidebar === "PRODUCTS"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon size={"large"} name="dolly" style={{ paddingTop: "3px" }} />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Produtos</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openBuscas}
        active={globalContext.sidebar === "SEARCH"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon size={"large"} name="search" style={{ paddingTop: "3px" }} />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Buscas</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openEmpresas}
        active={globalContext.sidebar === "COMPANIES"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon
              size={"large"}
              name="building"
              style={{ paddingTop: "3px" }}
            />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Empresas</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openCalculos}
        active={globalContext.sidebar === "CALCULOS"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon
              size={"large"}
              name="superscript"
              style={{ paddingTop: "3px" }}
            />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Cálculos</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Menu.Item
        className={"menuText"}
        onClick={openRotinas}
        active={globalContext.sidebar === "ROTINAS"}
        as="a"
      >
        <Grid>
          <Grid.Column style={{ marginLeft: "1rem", marginRight: "1.5rem" }}>
            <Icon
              size={"large"}
              name="clock"
              style={{ paddingTop: "3px" }}
            />
          </Grid.Column>
          <Grid.Column style={{ marginTop: "0.4rem" }}>
            <span className="menuVertical__item">Rotinas</span>
          </Grid.Column>
        </Grid>
      </Menu.Item>

      <Divider className="menuVertical__divider" />

      <Menu.Item style={{ paddingLeft: "26px", paddingBottom: "14px" }}>
        <span className="menuVertical__header">Notificações</span>
      </Menu.Item>

      <MobileNotification iconType="EXPANDED" />
      <WebNotifications iconType="EXPANDED" />
    </Sidebar>
  );
};

export default VerticalSidebarOpenProject;
