import Service from "./service";

export default class CompaniesService extends Service {
  static MAX_NUMBER_BY_PAGE = 100;

  // Empresas

  private static list = async (params) => {
    try {
      const page = params.page;
      const token = Service.token();
      const limitAndPageString = `limit=${CompaniesService.MAX_NUMBER_BY_PAGE}&page=${page}`;

      let searchString = "";
      if (params.payload.hasOwnProperty("searchKey"))
        searchString = `&search=[${params.payload.searchKey}]`;

      let orderAndOrderByString = "";
      if (params.order && params.orderBy)
        orderAndOrderByString = `&order=${params.order}&orderBy=${params.orderBy}`;

      const res = await fetch(
        `${Service.getAPIData()}/admin/empresas?${limitAndPageString}${searchString}${orderAndOrderByString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static create = async (data) => {
    try {
      return new Promise(async (resolve, reject) => {
        let payload = { empresa: data.payload };
        const response = await fetch(`${Service.getAPIData()}/admin/empresas`, {
          method: "POST",
          headers: Service.getHeadersPOST(),
          body: JSON.stringify(payload),
        });
        if (response.status === 201) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static update = async (data) => {
    try {
      return new Promise(async (resolve, reject) => {
        let payload = data.payload;
        let empresaId = data.id;
        const response = await fetch(
          `${Service.getAPIData()}/admin/empresas/${empresaId}`,
          {
            method: "PATCH",
            headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static delete = async (data) => {
    let { empresaId } = data;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/empresas/${empresaId}`,
          {
            method: "DELETE",
            headers: Service.getHeadersPOST(),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // Contatos

  private static getContatos = async (params) => {
    let empresaId = params.empresaId;
    try {
      const token = Service.token();
      const res = await fetch(
        `${Service.getAPIData()}/admin/empresas/${empresaId}/contatos`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static updateContato = async (data: any) => {
    let { empresaId, contatoId, payload } = data;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/empresas/${empresaId}/contatos/${contatoId}`,
          {
            method: "PATCH",
            headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static createContato = async (data: any) => {
    let { empresaId, payload } = data;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/empresas/${empresaId}/contatos`,
          {
            method: "POST",
            headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 201) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static deleteContato = async (data) => {
    let { contatoId, empresaId } = data;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/empresas/${empresaId}/contatos/${contatoId}`,
          {
            method: "DELETE",
            headers: Service.getHeadersPOST(),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // Usuários Associados a Empresa
  private static getClientesFromEmpresa = async (params: any) => {
    let empresaId = params.empresaId;
    try {
      const token = Service.token();
      const res = await fetch(
        `${Service.getAPIData()}/admin/empresas/${empresaId}/users`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static addClienteEmpresa = async (data: any) => {
    let { empresaId, payload } = data;
    try {
      const token = Service.token();
      const res = await fetch(
        `${Service.getAPIData()}/admin/empresas/${empresaId}/users`,
        {
          method: "POST",
          headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload),
        }
      );
      if (res.status === 201) return Promise.resolve(res.json());
      else  return Promise.reject(res.status);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getEmpresasFromClient = async (params: any) => {
    let userId = params.userId;
    try {
      const token = Service.token();
      const res = await fetch(
        `${Service.getAPIData()}/admin/users/${userId}/empresas`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static buscarUsuarios = async (params: any) => {
    let search = params.search;
    let empresaId = params.empresaId;
    try {
      const token = Service.token();
      const res = await fetch(
        `${Service.getAPIData()}/admin/empresas/${empresaId}/users/search?search=[${search}]`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static deletarAssociacaoCliente = async (data) => {
    let { userId, empresaId } = data;
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/empresas/${empresaId}/users/${userId}`,
          {
            method: "DELETE",
            headers: Service.getHeadersPOST(),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /* Public Calls */


  // Company

  static getCompanies = async (params) => {
    return await CompaniesService.list(params);
  };

  static createCompany = async (data) => {
    return await CompaniesService.create(data);
  };

  static updateCompany = async (data) => {
    return await CompaniesService.update(data);
  };

  static deleteCompany = async (data) => {
    return await CompaniesService.delete(data);
  };

    // Contacts

  static getContactsFromCompany = async (params) => {
    return await CompaniesService.getContatos(params);
  };

  static insertContact = async (params) => {
    return await CompaniesService.createContato(params);
  };

  static updateContact = async (params) => {
    return await CompaniesService.updateContato(params);
  };

  static deleteContact = async (params) => {
    return await CompaniesService.deleteContato(params);
  };

    // Clients

  static getClientsFromCompany = async (params) => {
    return await CompaniesService.getClientesFromEmpresa(params);
  };

  static getCompaniesFromClients = async (params) => {
    return await CompaniesService.getEmpresasFromClient(params);
  };

  static searchClients = async (params) => {
    return await CompaniesService.buscarUsuarios(params);
  };

  static addClientCompanyAssociation = async (params) => {
    return await CompaniesService.addClienteEmpresa(params);
  };

   static deleteClientCompanyAssociation = async (params) => {
    return await CompaniesService.deletarAssociacaoCliente(params);
  };
}
