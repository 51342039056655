import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Confirm, Image, Menu, Popup } from "semantic-ui-react";
import { logoClara, logoCores } from "#static/img";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Cookie from "js-cookie";
import { ProfilePopup } from "#components/profile";

/* Main Component */
let TopBar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("home");
  const { data } = useSelector((state: any) => ({ data: state.project }));
  const { theme } = useSelector((state: any) => ({ theme: state.darkMode }));
  const { userData } = useSelector((state: any) => ({ userData: state.user }));
  const dispatch = useDispatch();
  const [openPayment, setOpenPayment] = useState(false);
  const [profilePopup, setProfilePopup] = useState(false);
  const [userName, setUserName] = useState("");

  const [closeProjectConfirmation, setCloseProjectConfirmation] =
    useState(false);

  useEffect(() => {
    if (userData) {
      setUserName(userData.data.nome.split(" ")[0]);
    }
  }, [userData]);

  const toggleOpenPayment = () => {
    setOpenPayment(!openPayment);
  };

  const confirmCloseProject = () => {
    setCloseProjectConfirmation(true);
  };

  const controlPayment = (callbackData) => {
    setOpenPayment(callbackData);
  };

  const logout = () => {
    Cookie.remove("aTkn", { domain: process.env.REACT_APP_DOMAIN });
    window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`);
  };

  const closeProject = () => {
    dispatch({ type: "clearProject" });
    navigate("/");
  };

  const toggleDarkMode = () => {
    if (theme.darkMode) dispatch({ type: "setLightMode" });
    else dispatch({ type: "setDarkMode" });
  };

  return (
    <>
      <Confirm
        size={"mini"}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        content={
          "Tem certeza que deseja fechar o projeto atual e voltar para a seleção de projetos?"
        }
        confirmButton={<Button secondary>Sim</Button>}
        cancelButton={<Button className={"buttonCancel"}>Não</Button>}
        open={closeProjectConfirmation}
        onCancel={() => setCloseProjectConfirmation(false)}
        onConfirm={() => closeProject()}
      />
      <Menu
        size="small"
        style={{
          marginBottom: "0px",
        }}
        fluid
        secondary
        className={"topbarBackground"}
      >
        <Menu.Item className={"mainPageLogo sidebarImageHeader"}>
          <Image
            src={theme.darkMode ? logoClara : logoCores}
            className={"logoCores"}
            size={"small"}
            verticalAlign={"middle"}
          />
        </Menu.Item>
        <Menu.Menu position="right">
          <Popup
            className={"popupBackground topbarMenu"}
            position={"bottom left"}
            content={"Notificações aparecerão aqui"}
            trigger={<Menu.Item icon={"bell outline"} className={"menuIcon"} />}
          />

          <ProfilePopup openPayment={() => toggleOpenPayment()} />
        </Menu.Menu>
      </Menu>
    </>
  );
};

export default TopBar;
