import Service from "./service";

export default class MessagingService extends Service {


  static sendBroadcastApp = async (params) => {

    try {
      let payload = params;
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/messaging/mobile/broadcast`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({payload: payload})
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static sendBroadcastWeb = async (params) => {

    try {
      let payload = params;
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/messaging/web/broadcast`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({payload: payload})
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
