import Service from "./service";

export default class LicencesService extends Service {
  private static get = async (params) => {
    try {
      const page = params.page;
      const limit = params.limit != null && params.limit != undefined && params.limit > 0 ? params.limit : 100;
      const token = Service.token();
      const limitAndPageString = `limit=${limit}&page=${page}`;

      let searchString = '';
      if (params.payload.hasOwnProperty("searchKey"))
        searchString = `&search=[${params.payload.searchKey}]`;

      let orderAndOrderByString = '';
      if (params.order && params.orderBy)
      orderAndOrderByString = `&order=${params.order}&orderBy=${params.orderBy}`;
      const res = await fetch(
        `${Service.getAPIData()}/admin/licences?${limitAndPageString}${searchString}${orderAndOrderByString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static createLicence = async (params) => {

    try {
      let payload = params;
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/admin/transactions`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      });
      if(res.status === 201)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static getLicenceEmail = async (params) => {
    try {
      const token = Service.token();
      const userEmail = params.userEmail;
      let searchString = `email=${userEmail}`;
      const res = await fetch(
        `${Service.getAPIData()}/admin/licences?${searchString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) return Promise.resolve(res.json());
      else return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static freeze = async (params) => {
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/licences/${
            params.licenceId
          }/suspend`,
          {
            method: "PATCH",
            headers: Service.getHeadersPOST(),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static reactivate = async (params) => {
    try {
      let payload = {
        useLeftDays: true
      };
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/licences/${
            params.licenceId
          }/activate`,
          {
            method: "PATCH",
            headers: Service.getHeadersPOST(),
            body: JSON.stringify(payload)
          },
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static reverseTransactionFromLicence = async (params) => {
    try {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(
          `${Service.getAPIData()}/admin/transactions/reverse/${
            params.licenceId
          }`,
          {
            method: "PATCH",
            headers: Service.getHeadersPOST(),
          }
        );
        if (response.status === 200) resolve(response.json());
        else {
          try {
            response
              .json()
              .then(({ content }) => {
                reject();
              })
              .catch((err) => {
                console.log(err);
                reject(response.status);
              });
          } catch (e) {
            reject(response.status);
          }
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getLicences = async (params) => {
    return await LicencesService.get(params);
  };

  static freezeLicence = async (params) => {
    return await LicencesService.freeze(params);
  };

  static reactivateFreezedLicence = async (params) => {
    return await LicencesService.reactivate(params);
  };

  static reverseTransactionByLicence = async (params) => {
    return await LicencesService.reverseTransactionFromLicence(params);
  };

  static getLicencesByEmail = async (params) => {
    return await LicencesService.getLicenceEmail(params);
  };

  static createSingleLicence = async (params) => {
    return await LicencesService.createLicence(params);
  };
}
