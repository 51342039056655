export default class AuxiliaryFunctions {

  static convertDate = (Date) =>{
    let dateArr = Date.split('/')
    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0]
  };

  static formatName = (fullName) =>{
    let splitName = fullName.split(' ')
    let nameString = ""
    for(let i = 0; i < splitName.length; i++){
      if(i === 0) nameString += splitName[0];
      else if(i === splitName.length -1) nameString += (" "+splitName[splitName.length -1])
      else if(splitName[i].length > 3) nameString += (" "+splitName[i].substr(0,1).toUpperCase()+".")
    }
    return nameString
  }

}
