import Service from "./service";

export default class VouchersService extends Service {

  static get = async (params) => {

    try {
      let page = params.page;
      const token = Service.token()
      let searchString;
      if(params.payload.hasOwnProperty('searchKey'))
        searchString = `limit=100&page=${page}&search=[${params.payload.searchKey}]`
      else
        searchString = `limit=100&page=${page}`
      const res = await fetch(`${Service.getAPIData()}/admin/coupons?${searchString}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(res.status === 200)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static create = async (params) => {

    try {
      let payload = params.payload;
      const token = Service.token()
      const res = await fetch(`${Service.getAPIData()}/admin/coupons`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({coupon: payload})
      });
      if(res.status === 201)
        return Promise.resolve(res.json());
      else
        return Promise.reject(res.json());
    } catch (e) {
      return Promise.reject(e);
    }
  };



  static getVouchers = async(params) =>{
    return await VouchersService.get(params);
  }

  static createVoucher = async(params) =>{
    return await VouchersService.create(params);
  }

}
