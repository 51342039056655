import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Confirm,
  Input,
  Menu,
  Message,
  Modal,
  Tab,
} from "semantic-ui-react";
import {
  AddCoupon,
  AdicionarTempo,
  TempoValor,
} from "#components/voucher/voucherTypes";
import VouchersService from "../../services/vouchers.service";
import { MessageContext } from "#providers";
import { colors } from "#static/json";

interface FillParams {
  refresh: any;
}
const VoucherManager: React.FC<FillParams> = (props) => {
  const { t } = useTranslation();
  const createCouponRef = useRef();
  const createCloseProductRef = useRef();
  const createAddTimeRef = useRef();
  const voucherRef = useRef();

  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [voucherResponse, setVoucherResponse] = useState({
    codcupom: "",
    codigocupom: "",
    codusuario: null,
    codusuarioadmin: "",
    descricao: null,
    diasacrescentarlicenca: "",
    expiresat: "",
    numerousosemgeral: 1,
    numerousosporusuario: 1,
    quantidadelicencas: 1,
    tipo: "PRODUTO_COMPLETO",
    valortotal: "",
  });
  const [messageContext, setMessageContext] = useContext(MessageContext);

  const changeOpen = (callback) => {
    setOpen(callback);
  };

  const changeLoading = (callback) => {
    setLoading(callback);
  };

  const submitVoucher = (callback) => {
    setLoading(true);
    VouchersService.createVoucher({
      payload: callback,
    })
      .then((res) => {
        setVoucherResponse(res.content);
        setConfirmation(true);
        setLoading(false);
        props.refresh();
      })
      .catch((err) => {
        setLoading(false);
        setMessageContext({
          id: 1,
          title: "Erro ao cadastrar voucher",
          description: "Houve um erro ao cadastrar o voucher",
          backgroundColor: colors.error,
          icon: "exclamation circle",
        });
      });
  };

  const panes = [
    {
      menuItem: "Produto fechado",
      render: () => (
        <Tab.Pane attached={false}>
          <TempoValor
            refresh={props.refresh}
            open={open}
            changeOpen={changeOpen}
            submitVoucher={submitVoucher}
            ref={createCloseProductRef}
            changeLoading={changeLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Cupom",
      render: () => (
        <Tab.Pane attached={false}>
          <AddCoupon
            refresh={props.refresh}
            open={open}
            changeOpen={changeOpen}
            submitVoucher={submitVoucher}
            ref={createCouponRef}
            changeLoading={changeLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Voucher Tempo Extra",
      render: () => (
        <Tab.Pane attached={false}>
          <AdicionarTempo
            refresh={props.refresh}
            open={open}
            changeOpen={changeOpen}
            submitVoucher={submitVoucher}
            ref={createAddTimeRef}
            changeLoading={changeLoading}
          />
        </Tab.Pane>
      ),
    },
    /*
    {
      menuItem: "Customizado",
      render: () => (
          <>
            <Customizado refresh={props.refresh} open={open} changeOpen={changeOpen} submitVoucher={submitVoucher} ref={createCouponRef} changeLoading={changeLoading}/>
          </>
      ),
    },
     */
  ];

  // use small delay to get the form field and select it
  useEffect(() => {
    setTimeout(() => {
      selectVoucher();
    }, 100);
  }, [confirmation]);

  const selectVoucher = () => {
    if (voucherRef && voucherRef.current) {
      // @ts-ignore
      voucherRef.current.select();
    }
  };

  return (
    <>
      <Confirm
        onClose={() => setConfirmation(false)}
        open={confirmation}
        confirmButton={
          <Button content={"Ok"} onClick={() => setConfirmation(false)} />
        }
        cancelButton={false}
        content={
          <>
            <Message success fluid>
              {voucherResponse.tipo !== "PRODUTO_COMPLETO" && (
                <>
                  Voucher cadastrado com sucesso! Código:{" "}
                  {voucherResponse && voucherResponse["codigocupom"]}
                </>
              )}
              {voucherResponse.tipo === "PRODUTO_COMPLETO" && (
                <>
                  Voucher cadastrado com sucesso! Código:{" "}
                  {voucherResponse && voucherResponse["codigocupom"]} <br />
                  <Input
                    label={"Link copia e cola"}
                    ref={voucherRef}
                    value={
                      `https://web.matanativa.com.br/#/?voucherCode=` +
                      voucherResponse.codigocupom
                    }
                  />
                </>
              )}
            </Message>
          </>
        }
      />
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size={"large"}
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={
          <Menu.Item as="a" name="add">
            <Button
              icon="add"
              className="button--primary"
              content="Adicionar Voucher"
            />
          </Menu.Item>
        }
        open={open}
      >
        <Modal.Header>Criação de Voucher/Cupom</Modal.Header>
        <Modal.Content>
          <Tab
            menu={{ tabular: true }}
            onTabChange={(e, data) =>
              setSelectedTab(parseInt(data.activeIndex.toString()))
            }
            panes={panes}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            className={"buttonCancel"}
            loading={loading}
            content={"Fechar"}
            onClick={() => setOpen(false)}
          />

          {
            {
              0: (
                <>
                  <Button
                    content="Adicionar"
                    loading={loading}
                    // @ts-ignore
                    onClick={() => createCloseProductRef.current.submit()}
                    positive
                  />
                </>
              ),
              1: (
                <>
                  <Button
                    content="Adicionar"
                    loading={loading}
                    //@ts-ignore
                    onClick={() => createCouponRef.current.submit()}
                    positive
                  />
                </>
              ),
              2: (
                <>
                  <Button
                    content="Adicionar"
                    loading={loading}
                    //@ts-ignore
                    onClick={() => createAddTimeRef.current.submit()}
                    positive
                  />
                </>
              ),
              3: (
                <>
                  <Button
                    content="Adicionar"
                    loading={loading}
                    onClick={() => setOpen(false)}
                    positive
                  />
                </>
              ),
            }[selectedTab]
          }
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default VoucherManager;
