/**
 * redux to store the selected tab when switching from expanded and collapsed
 * @param state
 * @param action
 */
export function selectedMenu(
  state: { topbar: any; sidebar: any; index:any; colapsed: any } = {topbar: null, sidebar: "USERS", index: null, colapsed: true},
  action: { type: string; payload: { topbar: any; sidebar: any; index: any; colapsed:any; } }
) {
  switch (action.type) {
    case "setSelectedSidebar":
      return {...state,sidebar: action.payload.sidebar }
    case "setSidebarToggle":
      return {...state,colapsed: !state.colapsed }
    case "setSelectedSidebarCalc":
      return {...state,sidebar: action.payload.sidebar, index: action.payload.index }
    case "setSelectedTopbar":
      return {...state,topbar: action.payload.topbar }
    case "clearSelectedMenu":
      return {topbar: null, sidebar: "USERS", colapsed: true, index: null};
    default:
      return state;
  }
}
