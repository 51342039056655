const languages = ["pt"];

const lngNamespaces = [
  "button",
  "message",
  "input",
  "projects",
  "calculations",
  "info",
  "calculationsParams",
  "editProject",
];

const langs = {};

languages.map((lng) => {
  const obj = { translation: {} };
  lngNamespaces.map((namespace) => {
    obj["translation"][namespace] = require(`./${lng}/${namespace}.json`);
  });
  langs[lng] = obj;
});

export { languages, langs, lngNamespaces };
