import React, { createContext, useReducer } from "react";

export const GlobalContext = createContext(null);

const { Provider } = GlobalContext;

const initialState = {
  topbar: null,
  sidebar: "STATUS",
  index: null,
  colapsed: true,

  licenceStatus: null,
  licenceMessage: "Carregando informações da licença",
};

const reducer = (state, action) => {
  switch (action.type) {
    /**
     * NAVIGATION SECTION
     */
    case "setSelectedSidebar":
      return { ...state, sidebar: action.payload.sidebar };
    case "clearProject":
      return {
        ...state,
        sidebar: "PROJECT",
        filters: [],
        filterNames: [],
        filtersTrees: [],
        filterNamesTrees: [],
      };
    case "setSidebarToggle":
      return { ...state, colapsed: !state.colapsed };
    case "setSelectedSidebarCalc":
      return {
        ...state,
        sidebar: action.payload.sidebar,
        index: action.payload.index,
      };
    case "setSelectedTopbar":
      return { ...state, topbar: action.payload.topbar };
    case "clearSelectedMenu":
      return { topbar: null, sidebar: "PROJECT", colapsed: true, index: null };

    default:
      return state;
  }
};

const GlobalContextProvider = ({ children }) => {
  const [globalContext, dispatch] = useReducer(reducer, initialState);
  return <Provider value={[globalContext, dispatch]}>{children}</Provider>;
};

GlobalContextProvider.context = GlobalContext;

export default GlobalContextProvider;
